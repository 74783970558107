<template>
  <div>
    <router-link to="/plan/yoga" class="back-link">
      <Flexy><img :src="ArrowRightIcon" /> <p>{{t('Bonus')}}</p></Flexy>
    </router-link>
    <div class="boxes light purple">
      <Box :title="list.display_name" :thumb="list.thumbnail" :link="'/plan/yoga/bonus/'+list.name" v-for="(list, index) in lists" :key="index">
        <p>{{list.display_content}}</p>
      </Box>
    </div>
  </div>
</template>

<script>

import t from '@/lib/Locale';
import Box from "@/components/ui/widgets/Box";
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';

import Fito from "@/lib/fito";
import Flexy from "@/components/ui/widgets/Flexy";

let folderName = "yoga-bonus";

export default {
  name: "YogaBonus",
  props: ["meta"],
  components: {Flexy, Box},
  data() {
    return {
      ArrowRightIcon,
      lists: []
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')


    const mediaLists = await Fito().Plan.getMediaLists()
    const lists = mediaLists.filter(it => it.folder === folderName).sort((a,b) => a.sort < b.sort ? -1:1);
    if (lists.length > 0) {
      this.lists = lists;
    }

  },

  methods : {
    t,
  },

  watch : {
  }

}
</script>
