export const allergies = [
    {name: "חלב", ingredients: ["חלב"], butNot:["סויה","אורז","שקדים","שיבולת שועל"]},
    {name: "אגוזים", ingredients: ["אגוז","פקאן"]},
    {name: "בוטנים", ingredients: ["בוטן","בוטנים"]},
    {name: "דגים", ingredients: ["דג","טונה","סלמון","מקרל"]},
    {name: "פירות ים", ingredients: ["שרימפס"]},
    {name: "ביצים", ingredients: ["ביצה","ביצים"]},
    {name: "שומשום", ingredients: ["שומשום","טחינה"]},
    {name: "סויה", ingredients: ["סויה"]},
    {name: "חיטה", ingredients: ["חיטה"]},
    {name: "קטניות", ingredients: ["שעועית","חומוס","עדשים","פול","סויה"]},
]


export function setFilters(tags, allergies) {
    localStorage.setItem('recipe-filters', JSON.stringify({tags, allergies}))
}

export function getFilters() {
    let filters = localStorage.getItem('recipe-filters')
    return JSON.parse(filters)
}

export function savedFiltersCount() {
    let filters = localStorage.getItem('recipe-filters')
    filters = JSON.parse(filters);
    return filters && filters.tags ? filters.tags.length + filters.allergies.length : 0
}

export function applySavedFilters(recipes) {
    let filters = localStorage.getItem('recipe-filters'),
        allergy;

    filters = JSON.parse(filters);
    if (!filters) return recipes;

    return recipes.filter(recipe => {
        if (filters.allergies && filters.allergies.length) {
            for (let a of filters.allergies) {
            allergy = allergies.find(it => it.name === a)
            for (let ing of recipe.ingredients) {
                for (let allergyIngredient of allergy.ingredients) {
                    if (ing.ingredient.name.indexOf(allergyIngredient) > -1) {
                        let found = false
                        if (allergy.butNot) {
                            for (let butNot of allergy.butNot) if (ing.ingredient.name.indexOf(butNot) > -1) {
                                found = true;
                                break;
                            }
                        }
                        if (!found) {
                            return false
                        }
                    }
                }
            }
            }
        }

        if (filters.tags && filters.tags.length) {
            let hasTag = false
            for (let tag of filters.tags) {
                for (let rTag of recipe.tags) {
                    if (rTag.tag_id === tag) { hasTag = true; break }
                }
            }
            if (!hasTag) return false
        }

        return true
    })
}

export function populateCategories(categories, recipes, onlyWithRecipes = false) {
    let usedImages = [], catRecipes, recipe, lastImage

    for (let category of categories) {
        lastImage = null
        catRecipes = recipes.filter(it => it.categories.filter(c => c.category_id === category.id).length > 0)
        recipe = catRecipes[Math.floor(Math.random()*catRecipes.length)]
        if (catRecipes.length === 0) continue;

        if (!category.image && recipe.images.length) {
            lastImage = recipe.images[0].image

            if (usedImages.indexOf(lastImage) > -1) {
                let attempts = 5, ind
                while(usedImages.indexOf(lastImage) > -1 && attempts > 0) {
                    recipe = catRecipes[Math.floor(Math.random()*catRecipes.length)]
                    for (ind = 0; ind < recipe.images.length; ind++)
                        if (recipe.images[ind] && usedImages.indexOf(recipe.images[ind].image) === -1) category.image = recipe.images[ind].image
                    attempts--
                }
            }
            else {
                category.image = lastImage
                usedImages.push(lastImage)
            }
        }

        category.recipes = catRecipes.length

        if (lastImage && !category.image) category.image = lastImage
    }

    if (onlyWithRecipes) categories = categories.filter(it => it.recipes > 0)
    return categories
}


export function searchRecipes(search, recipes, categories) {

    search = search.trim()
    const terms = search.split(/ /g)
    for (let term of terms) {
        let matchedCategories = categories.filter(cat => {
            if (cat.name.indexOf(term) > -1) return true;
        }).map(it => it.id)

        recipes = recipes.filter(recipe => {
            for (let cat of recipe.categories) {
                if (matchedCategories.indexOf(cat.category_id) > -1) return true
            }
            for (let ing of recipe.ingredients) {
                if (ing.ingredient && ing.ingredient.name.indexOf(term) > -1) return true;
            }
            if (recipe.name.indexOf(term) > -1) return true;
        })
    }
    return recipes

}
