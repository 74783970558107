import {ApiRequest, ApiCall} from '../lib/Api';
import ApiRoutes from "../lib/ApiRoutes";

export default class login {

    constructor(fito) {
        this.fito = fito;
        this.callbacks = { signup: [] }
    }

    loadToken() {
        const token = localStorage.getItem('login-token')
        if (token) this.fito.token = token;
    }

    saveToken(token) {
        this.fito.token = token;
        return localStorage.setItem('login-token', token)
    }

    onLogin(callback) {
        this.callbacks.login.push(callback)
    }

    onLogout(callback) {
        this.callbacks.logout.push(callback)
    }

    async Signup(first_name, last_name, email, password, gender, token = null) {
        let data = {first_name, last_name, email, password, gender, token}
        const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.signUp, data), this.fito.ApiKey, this.fito.token)
        if (r.res === "error") throw r.error
        if (r.token) this.fito.token = r.token
        for (let cb of this.callbacks.signup) cb()
        return r;
    }

    async RecoverDetails(token) {
        let data = {token}
        const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.signupRecoverDetails, data), this.fito.ApiKey, this.fito.token)
        if (r.res === "error") throw r.error
        return r;
    }
    async GetTransactionToken(plan, name = '', email='',phone='', amount, token = '') {
        let data = {plan, name, amount, token, email, phone}
        const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.getTransactionToken, data), this.fito.ApiKey, this.fito.token)
        if (r.res === "error") throw r.error
        return r;
    }

    async RenewSubscriptionToken() {
        const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.RenewSubscriptionToken), this.fito.ApiKey, this.fito.token)
        if (r.res === "error") throw r.error
        return r;
    }

    async CompleteSignup(token) {
        let data = {token}
        const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.completeSignup, data), this.fito.ApiKey, this.fito.token)
        if (r.res === "error") throw r.error
        return r;
    }

    async GetPlans() {
        const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.getSignupPlans), this.fito.ApiKey, this.fito.token)
        if (r.res === "error") throw r.error
        return r.plans;
    }

    async setPlan(token, planId) {
        const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.setSignupPlan, {plan: planId, token}), this.fito.ApiKey, this.fito.token)
        if (r.res === "error") throw r.error
        return r
    }

}
