<template>
  <div class="recipes-home container">
    <div class="search-filters">
      <Flexy :between="true">
        <h2><img :src="XIcon" @click="onBack" class="close-btn" /> {{t("Filters")}} <small>({{filtersCount}})</small></h2>
        <a class="local" @click="clearAllFilters">{{t("Clear All")}}</a>
      </Flexy>
      <SearchFilters :tags="tags" @change="onFilters" :filters="filters" />
    </div>
  </div>
</template>

<script>

import Fito from '@/lib/fito';

import t from "@/lib/Locale";
import Flexy from "@/components/ui/widgets/Flexy";
import XIcon from '@/assets/icons/recipes/x.svg';
import SearchFilters from "@/components/ui/widgets/SearchFilters";
import {getFilters, setFilters} from "@/lib/RecipeUtils";

export default {

  name: "MenusMealsFılters",
  components: {SearchFilters, Flexy, },
  data : function() {
    return {
      XIcon,
      tags : [],
      filters : {},
      filtersCount : 0
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')



    this.tags = await Fito().RunAction('get-recipe-tags')
    this.filters = getFilters()
    if (this.filters) this.filtersCount = this.filters.allergies.length + this.filters.tags.length
  },

  beforeDestroy() {
    clearTimeout(this.updateTimeout)
  },

  methods: {
    t,

    onBack() {
      this.$router.push('/plan/menus/select-meal')
    },

    onFilters(tags, allergies) {
      setFilters(tags, allergies)
      this.filters = {allergies, tags}
      this.filtersCount = this.filters.allergies.length + this.filters.tags.length
    },
    clearAllFilters() {
      setFilters([],[])
      this.filters = {allergies: [], tags: []}
      this.filtersCount = 0
    }
  },

  watch:{
    $route() {
      this.search = this.$route.params.search ? this.$route.params.search : ''
    }
  }

}
</script>
