<template>
  <div class="modal" @click="onDismiss">
    <div class="modal-inner" @click="(e) => e.stopPropagation()">
      <div class="modal-close" v-if="closeButton" @click="onDismiss"><img :src="xIcon" /></div>
      <slot></slot>
    </div>
  </div>
</template>

<script>

import xIcon from '@/assets/icons/x.svg';

export default {
  props : {
    dismiss : { type: Function, default: null},
    closeButton : { type: Boolean, default: true}
  },
  name: "Modal",
  data() {
    return {
      xIcon,
    }
  },
  methods: {
    onDismiss() {
      if (this.dismiss) this.dismiss()
    }
  }
}
</script>
