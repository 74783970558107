export default {

    // common
    checkApi :              { path: "/api/hello", method: "post", body: [], apiKey: true},
    runAction :             { path: "/api/run-action", method: "post", param: true, body: true, apiKey: true, token: true },
    contact :               { path : "/contact/send", method: "post", body: ["email", "phone","name", "subject", "message"], apiKey: true},

    // login
    checkLogin :            { path : "/login/check-login", method: "post", body: ["token"], apiKey: true},
    login :                 { path : '/login/login', method: "post", body: ["email", "password","external_id"], apiKey: true},
    confirmRecoveryCode :   { path : '/login/confirm-recovery-code', method: "post", body: ["recovery_code"], apiKey: true},
    resetPassword :         { path : '/login/reset-password', method: "post", body: ["password", "recovery_code"], apiKey: true},
    forgotPassword :        { path : '/login/recover-password', method: "post", body: ["identifier"], apiKey: true},

    // Sign Up
    signUp :                { path : "/signup/sign-up", method: "post", body: ["email", "first_name", "last_name", "password", "answers", "gender", "token"], apiKey: true },
    signupRecoverDetails :  { path : "/signup/recover", method: "post", body: ["token"], apiKey: true },
    getTransactionToken :   { path : "/signup/get-transaction-token", method: "post", body: ["plan","name","email","phone","amount","token","pay_immediately"], apiKey: true },
    getSignupPlans :        { path : "/signup/get-plans", method: "get", apiKey: true },
    setSignupPlan :         { path : "/signup/set-plan", method: "post", body: ["token","plan"], apiKey: true },
    completeSignup :        { path : "/signup/complete-signup", method: "post", body: ["token"], apiKey: true },
    RenewSubscriptionToken :{ path : "/signup/renew-subscription-token", method: "get", apiKey: true, token: true },

    // media lists
    getMediaLists :         { path : "/media-list/list", method: "get", apiKey: true, token: true},

    // exclusives
    getExclusives :         { path : "/exclusive/list", method: "get", apiKey: true, token: true},

    // videos
    getVideos :             { path : "/video/list", method: "get", apiKey: true, token: true},

    // account
    updateAccountDetails :  { path : "/plan/update-account-details", method: "post", body: ["first_name","last_name","email"], apiKey: true, token: true },
    cancelSubscription:     { path: "/plan/cancel-subscription", method: "post", token: true, apiKey: true, body: ["cancellation_reason"] },

    // plan
    changePassword :        { path : '/plan/change-password', method: "post", body: ["password", "password2"], apiKey: true},
    log :        { path : '/plan/log', method: "post", body: ["type", "details"], apiKey: true},

};
