<template>
  <div>
    <ExpiredForm v-if="expired" />
    <div v-if="suspended">
      <div class="boxes light">
        <Box :title="t('Account Suspended')" link="/contact"><p>{{suspended}}</p></Box>
      </div>
    </div>
    <div v-if="!expired && !suspended">
      <div class="boxes centered">
        <h4>{{t("Choose the plan that is right for you:")}}</h4>
      </div>
      <div class="boxes light">
        <router-link v-for="(plan, ii) in plans" :key="ii" class="box" :to="'/plan/menus/choose-plan/'+plan.id">
          <div class="flexy">
            <div class="flex-1">
              <h3>{{plan.name}}</h3>
              <h4>{{plan.subtitle}}</h4>
              <p>{{plan.short_description}}</p>
            </div>
            <div class="flex-0">
              <img :src="ChevronIcon" />

            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ExpiredForm from "@/components/forms/ExpiredForm";
import ChevronIcon from '@/assets/icons/chevron.svg';

import Fito from "@/lib/fito";
import t from "@/lib/Locale"
import Box from "@/components/ui/widgets/Box";

export default {
  name: "MenusChoosePlan",
  components: {Box, ExpiredForm},

  data() {
    return {
      ChevronIcon,
      expired: false,
      suspended: false,
      plans : null
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')



    if (Fito().suspended) { this.suspended = Fito().suspended }


    try {

      const m = await Fito().RunAction('get-menus-plans');
      this.plans = m.filter(plan => {
        if (login.meta['pregnancy-weeks'] && plan.type === 'pregnancy') return true
        if (!login.meta['pregnancy-weeks'] && plan.type !== 'pregnancy') return true
      });

    } catch (e) {
      if (e.err_type === 'expired') {
        this.expired = true;
      }
    }
  },

  methods : {
    t,
  }
}
</script>
