<template>
  <div class="view-contact container">
    <div class="centered">
      <h3>{{t('Error')}}</h3>
      <p>{{t("The application version seems to be too old. Please reload the page and if the error continues, contact support.")}}</p>
    </div>
  </div>
</template>

<script>
import t from "@/lib/Locale"

export default {
  name: "Error",
  async mounted() {
    history.pushState({},'','/')
  },
  methods : {
    t,
  }
}
</script>
