<template>
  <div>
    <ExpiredForm v-if="expired" />
    <div v-if="suspended">
      <div class="boxes light">
        <Box :title="t('Account Suspended')" link="/contact"><p>{{suspended}}</p></Box>
      </div>
    </div>
    <Modal v-if="isWarPlan" class="pre" :closeButton="false">
      <p>{{t('war-thank-you')}}</p>
      <button class="btn" @click="$router.push('/plan/renew')">{{t("war-signup-button")}}</button>
    </Modal>
    <div v-if="!expired && !suspended">
      <Vimeo v-if="introVideo" :path="introVideo" />
      <div class="boxes light">
        <LiveNotification path="2402889" starts="2022-09-05T10:00:00.000Z" ends="2022-09-05T14:30:00.000Z" />
        <Box :title="list.display_name" :progress="list.score%400/400" :points="list.score" :link="'/plan/yoga/'+list.name" v-for="(list, index) in lists" :key="index">
          <p class="primary small">{{t('Level {0}, {1} points', list.level, list.score)}}</p>
        </Box>
        <Box :title="t('Workshops')" :link="'/plan/yoga/workshops'">
          <p>{{t("Various Workshops")}}</p>
        </Box>
        <Box :title="t('Bonus')" :link="'/plan/yoga/bonus'">
          <p>{{t("Additional Bonus Practices")}}</p>
        </Box>
      </div>
    </div>
    <PhonePop v-if="2<1" :notifications="notifications" />
  </div>
</template>

<script>

import t from '@/lib/Locale';
import Box from "@/components/ui/widgets/Box";
import Fito from "@/lib/fito";
import Vimeo from "@/components/ui/widgets/Vimeo";
import ExpiredForm from "@/components/forms/ExpiredForm";
import PhonePop from "@/components/ui/widgets/PhonePop";
import LiveNotification from "@/components/ui/widgets/LiveNotification";
import Modal from "@/components/ui/widgets/Modal.vue";

let basicPlan = "yoga-basic";
let pregnancyPlan = "yoga-pregnancy";
const WAR_PLAN_ID = 9;

export default {
  name: "YogaHome",
  props: ["meta"],
  components: {Modal, LiveNotification, PhonePop, ExpiredForm, Vimeo, Box},

  data() {
    return {
      lists: [],
      notifications: [],
      expired: false,
      suspended: false,
      third : null,
      isWarPlan: false,
      introVideo: null
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin(true);
    if (!login) return this.$router.push('/plan/login');

    if (login.plan.id === WAR_PLAN_ID) {
      this.isWarPlan = true;
    }

    if (Fito().suspended) { this.suspended = Fito().suspended }

    if (login.notifications) this.notifications = login.notifications;

    let folderName = basicPlan;
    const meta = login.meta;
    if (meta['pregnancy-weeks']) { // pregnancy mode
        folderName = pregnancyPlan
        const {activity, experience} = meta['signup-stats'] ? meta['signup-stats'] : meta['personal_stats'];
        if (activity === "yes" && experience === "yes") {
          // .. cool!
          this.introVideo = '682307311'
        }
        else {
          this.introVideo = '682306883'
          this.third = Math.floor(meta['pregnancy-weeks']/40*3)
        }
    }

    try {
      const mediaLists = await Fito().Plan.getMediaLists()
      let lists = mediaLists.filter(it => it.folder === folderName).sort((a,b) => a.sort < b.sort ? -1:1);
      if (this.isWarPlan) {
        lists = lists.filter(it => it.name !== "advanced" && it.name !== "advanced-plus")
      }
      if (lists.length > 0) {
        this.lists = lists;
      }


      if (this.third !== null) {
        this.lists = [this.lists[this.third]]
      }

      this.calculateScore()
    } catch (e) {
      if (e.err_type === 'expired') {
        this.expired = true;
      }
    }
  },

  methods : {
    t,
    calculateScore() {
      for (let list of this.lists) {
        list.score = 0
        if (this.meta && this.meta['completed-practices'] && this.meta['completed-practices'] instanceof Array) {
          for (let p of this.meta['completed-practices']) if (p.list === list.name) list.score += 100
        }
        if (this.meta && this.meta['completed_yoga']) {
          // we're phasing out old scores, sorry
        }
        list.level = Math.ceil((1+list.score) / 400)
      }
    }
  },

  watch : {
    meta() {
      this.calculateScore()
    }
  }

}
</script>
