<template>
  <div class="choose-plan-form">
    <span class="back-link raw" v-if="onCancel">
      <Flexy><img :src="ArrowRightIcon" @click="onCancel" /> <p>{{t('Choose the right plan for you')}}</p></Flexy>
    </span>
    <div class="boxes light plans">
      <Box v-for="(plan, ii) in myPlans" :key="ii" :title="plan.name" :action-icon="ChoosePlanIcon" @click="() => onSelect(plan)">
        <div class="prices">
          <div class="crossed-price" v-if="plan.full_price">{{t("{0} nis", plan.full_price)}}</div>
          <div class="price">{{t("{0} nis", plan.price)}}</div>
          <div class="description" v-if="plan.description">{{plan.description}}</div>
        </div>
      </Box>
    </div>
    <div>
      <div class="centered small">
        {{t("You can always cancel your plan in your user profile")}}
      </div>
    </div>
  </div>
</template>

<script>
import t from "@/lib/Locale";
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';
import ChoosePlanIcon from '@/assets/icons/choose-plan.svg';
import Flexy from "@/components/ui/widgets/Flexy";
import Box from "@/components/ui/widgets/Box";


export default {
name: "ChoosePlanForm",
  components: {Box, Flexy},
  props : {
    plans : {type: Array},
    onSelect : {type: Function},
    onCancel : {type: Function},
  },

  data : function() {
    return {
      ArrowRightIcon, ChoosePlanIcon,

      first_name: '',
      last_name: '',
      email: '',
      password: '',
      remember: false,
    }
  },

  mounted() {
  },

  computed : {
    myPlans() {
      return this.plans
    }
  },

  methods : {
    t,

    trySubmit : function() {
      if (this.onSubmit) {
        this.onSubmit(this.first_name, this.last_name, this.email, this.password);
      }
    },

  },


}
</script>
