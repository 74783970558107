<template>
  <div class="search-input-container">
    <img :src="SearchIcon" class="prefix" />
    <div :class="'suffix'+(active?' active':'')" @click="onFilters" >
      <img :src="FiltersIcon" />
    </div>
    <input type="text" class="search-input" ref="input" v-model="search" :placeholder="placeholder" @input="onChange" @click="onFocus" @blur="onBlur" />
  </div>
</template>

<script>

import SearchIcon from '@/assets/icons/recipes/search.svg';
import FiltersIcon from '@/assets/icons/recipes/filters.svg';
import {savedFiltersCount} from "@/lib/RecipeUtils";

export default {
  name: "SearchInput",
  props: {
    placeholder : {type: String, default: ""},
    value : {type: String, default: ""},
    autoFocus: {type: Boolean, default: false}

  },
  data() {
    return {
      SearchIcon, FiltersIcon,
      search : '',
      active: false
    }
  },
  mounted() {
    this.search = this.value
    if (this.autoFocus) this.$refs.input.focus()

    if (savedFiltersCount() > 0) {
      this.active = true
    }
  },
  methods : {
    onChange() {
      this.$emit('change', this.search)
    },
    onFilters() {
      this.$emit('filters')
    },
    onFocus() {
      this.$emit('focus')
    },
    onBlur() {
      this.$emit('blur')
    }
  },
  watch : {
    value() {
      this.search = this.value
    }
  }
}
</script>
