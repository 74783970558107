<template>
  <div class="container flexy col">
    <div class="flexy col flex-1">
      <router-link to="/plan/menus/choose-plan" class="back-link">
        <Flexy><img :src="ArrowRightIcon" /> <p>{{plan.name}}</p></Flexy>
      </router-link>
      <div class="padded light flex-1 menus-plan-details" v-if="plan">
        <div class="flex-1">
          <h3>{{plan.name}}</h3>
          <h4>{{plan.subtitle}}</h4>
          <p>{{plan.description}}</p>
        </div>
      </div>
      <div class="floating-meal-select">
        <a class="btn" @click="activatePlan">{{t("Activate plan")}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronIcon from '@/assets/icons/chevron.svg';
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';

import Fito from "@/lib/fito";
import t from "@/lib/Locale";
import Flexy from "@/components/ui/widgets/Flexy";

export default {
  name: "MenusPlanDetails",
  components: {Flexy},

  props: ['meta'],

  data() {
    return {
      ChevronIcon, ArrowRightIcon,
      expired: false,
      plans : null
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')



    try {
      const m = await Fito().RunAction('get-menus-plans');
      this.plans = m;

    } catch (e) {
      if (e.err_type === 'expired') {
        this.expired = true;
      }
    }
  },
  computed : {
    plan() {
      return this.plans ? this.plans.find(it => it.id === this.$route.params.id*1) : {}
    }
  },
  methods : {
    t,
    async activatePlan() {
      await Fito().RunAction('set-menus-plan', {plan_id: this.$route.params.id*1})
      await this.$router.push('/plan/menus')
    }
  }
}
</script>
