<template>
  <div class="recipes-home">
    <router-link to="/plan/recipes" class="back-link">
      <Flexy><img :src="ArrowRightIcon" /> <p>{{t('To All Recipes')}}</p></Flexy>
    </router-link>
    <div class="container">
      <SearchInput :value="search" :placeholder="t('Search for a recipe')" :auto-focus="true" @focus="onSearchFocus" @blur="onSearchBlur" @change="onSearchInput" @filters="onSearchFilter" />
      <SearchCategories v-if="showSearchCategories" :categories="activeCategories" @change="onSearchCategory" />
      <SearchResults v-if="showResults" :search="search" :recipes="recipes" :categories="activeCategories" :tags="tags" />
    </div>
  </div>
</template>

<script>

import Fito from '@/lib/fito';
import t from "@/lib/Locale";
import SearchInput from "@/components/ui/widgets/SearchInput";
import Flexy from "@/components/ui/widgets/Flexy";

import RecipePlaceholderImage from '@/assets/images/recipe-placeholder.png';
import TimeIcon from '@/assets/icons/time.svg';
import TimeWhiteIcon from '@/assets/icons/time-white.svg';
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';

import {applySavedFilters, populateCategories} from "@/lib/RecipeUtils";
import SearchCategories from "@/components/ui/widgets/SearchCategories";
import SearchResults from "@/components/ui/widgets/SearchResults";

const ITEMS_IN_POPULAR_SLIDER = 5;

export default {

  name: "RecipeSearch",
  components: {SearchResults, SearchCategories, Flexy, SearchInput},
  data : function() {
    return {
      RecipePlaceholderImage, TimeWhiteIcon, TimeIcon, ArrowRightIcon,

      recipes: [],
      categories: [],
      tags: [],

      search: '',
      searchFocus: false,
      searchFilters: false,
      updateTimeout: null,
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')



    this.search = this.$route.params.search ? this.$route.params.search : '';

    this.recipes = await Fito().RunAction('get-recipes');
    this.categories = await Fito().RunAction('get-recipe-categories')
    this.tags = await Fito().RunAction('get-recipe-tags')

    this.recipes = applySavedFilters(this.recipes)

  },

  beforeDestroy() {
    clearTimeout(this.updateTimeout)
  },

  computed : {
    topPopular() {
      return this.recipes.slice(0, ITEMS_IN_POPULAR_SLIDER)
    },
    bottomPopular() {
      return this.recipes.slice(ITEMS_IN_POPULAR_SLIDER)
    },
    activeCategories() {
      if (!this.recipes.length || !this.categories.length) return []
      return populateCategories(this.categories, this.recipes, true);
    },
    showSearchCategories() {
      return !this.search.length || this.search.trim().length === 0
    },
    showResults() {
      return true
    }
  },

  methods: {
    t,

    getThumb(recipe) {
      if (recipe.images && recipe.images.length > 0) return recipe.images[0].image
      return '';
    },

    updateLocation() {
      let dest = '/plan/recipes/search/'+this.search
      if (this.$route.path !== dest) this.$router.push(dest);
    },

    onSearchInput(search) {
      this.search = search;
      clearTimeout(this.updateTimeout)
      this.updateTimeout = setTimeout(() => { this.updateLocation() }, 1500)
    },
    onSearchFilter() {
      this.$router.push('/plan/recipes/filters')
    },
    onSearchFocus() {
      this.searchFocus = true
    },
    onSearchBlur() {
      setTimeout(() => {
        this.searchFocus = false
      }, 300)
    },
    onSearchCategory(category) {
      this.search = category.name
    }
  },

  watch:{
    $route (){
      this.search = this.$route.params.search ? this.$route.params.search : ''
    }
  }

}
</script>
