<template>
  <div class="recipes-home">
    <div class="relative">
      <div class="top-left">
        <a class="btn" @click="toggleFavorite"><img :src="favorite?HeartFullIcon:HeartIcon"></a>
      </div>
    </div>
    <Scroller :items="recipe.images" type="small">
      <template v-slot:default="s">
        <div :style="{backgroundImage: `url(${s.item.image})`}" class="full-slide details">
        </div>
      </template>
    </Scroller>
    <div class="container">
      <div class=" recipe-meta">
        <h3>{{recipe.name}}</h3>
        <Flexy class="wraps">
          <img :src="TimeIcon" />
          <p>{{t("Prep time: {0} minutes", recipe.prep_time)}}</p>
          <p v-for="(tag, ii) in allowedTags" :key="ii">{{tag.name}}</p>
        </Flexy>
      </div>
      <div class="recipe-ingredients" v-if="recipe.ingredients">
        <Flexy :between="true">
          <h3>{{t("Ingredients ({0})", recipe.ingredients.length)}}</h3>

          <Flexy class="servings-amount">
            <p>{{t("Servings amount:")}}</p>
            <p class="up-down" @click="recipeAmountDown">-</p>
            <p class="num">{{servings}}</p>
            <p class="up-down" @click="recipeAmountUp">+</p>
          </Flexy>
        </Flexy>

        <div class="ingredients-list">
          <p v-for="(ingredient, ii) in recipe.ingredients" :key="ii">
            <span>{{ingredientRow(ingredient)}}</span>
          </p>
        </div>
      </div>

      <div class="instructions-list">
        <h3>{{t("Preparation Instructions")}}</h3>
        <ul>
          <li v-for="(instruction, ii) in recipe.instructions" :key="ii">
            <h3>{{instruction.title}}</h3>
            <p>{{instruction.content}}</p>
          </li>
        </ul>
      </div>

    </div>
  </div>
</template>

<script>

import Fito from '@/lib/fito';
import HeartIcon from '@/assets/icons/heart.svg';
import HeartFullIcon from '@/assets/icons/heart-full.svg';

import t from "@/lib/Locale";
import Scroller from "@/components/ui/widgets/Scroller";

import TimeIcon from '@/assets/icons/time.svg';
import Flexy from "@/components/ui/widgets/Flexy";

export default {

  name: "RecipeDetails",
  components: {Flexy, Scroller},
  data : function() {
    return {
      TimeIcon, HeartIcon, HeartFullIcon,
      recipe : {},
      servings: 1,
      favorite: false
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')



    this.recipes = await Fito().RunAction('get-recipes');
    this.categories = await Fito().RunAction('get-recipe-categories')
    this.tags = await Fito().RunAction('get-recipe-tags')
    this.recipe = this.recipes.find(it => it.id === this.$route.params.id*1)
    if (!this.recipe) this.recipe = {}

    const meta = login.meta;
    if (meta['recipe-favorites']) {
      const found = meta['recipe-favorites'].find(it => it*1 === this.recipe.id*1)

      if (found) this.favorite = true
    }

  },

  methods: {
    t,
    recipeAmountUp() {
      if (this.servings < 20) this.servings++
    },
    recipeAmountDown() {
      if (this.servings > 1) this.servings--
    },

    ingredientRow(ing) {

      let amount = ing.serving_amount * this.servings, name = ing.ingredient.name,
          amtProp = ing.serving_size_id ? (ing.serving_size.name_plural && amount > 1 ? 'name_plural' : 'name'): '',
          amtName = ing.serving_size_id ? ing.serving_size[amtProp] : ''

      if (amtName.indexOf('שלושת רבע') === 0) {
        amtName = amtName.substr(6);
        amount *= 3;
      }

      if (amount % 4 === 0 && amtName.indexOf('רבע') === 0) {
        amtName = amtName.substr(4);
        amount /= 4;
      }
      else if (amount % 3 === 0 && amtName.indexOf('שליש') === 0) {
        amtName = amtName.substr(5);
        amount /= 3;
      }
      else if (amount % 2 === 0 && amtName.indexOf('רבע') === 0) {
        amtName = "חצי "+amtName.substr(4);
        amount /= 2;
      }

      if (amount % 2 === 0 && amtName.indexOf('חצי') === 0) {
        amtName = amtName.substr(4);
        amount /= 2;
      }

      if (amount %2 !== 0 && amount > 1 && amtName.indexOf('חצי') === 0) {
        amount = Math.floor(amount/2)
        amtName = amtName.substr(4) + ' וחצי'
      }

      if (amount %4 !== 0 && amount > 1 && amtName.indexOf('רבע') === 0) {
        if (amount > 4) {
          amount /= 4
          amtName = amtName.substr(4) + (
              amount - Math.floor(amount) < 0.5 ? ' ורבע' : (amount - Math.floor(amount) > 0.5  ? ' ושלושת רבעי' : ' וחצי')
          )
          amount = Math.floor(amount)
        }
        else {
          amount /= 4
          amtName = (amount < 0.5 ? 'רבע ' : (amount > 0.5 ? 'שלושת רבעי ' : 'חצי ')) + amtName.substr(4)
        }
      }

      if (amount %3 !== 0 && amtName.indexOf('שליש') === 0) {
        if (amount > 3) {
          amount /= 3
          amtName = amtName.substr(5) + (amount - Math.floor(amount) < 0.5 ? ' ושליש' : ' ושני שליש')
          amount = Math.floor(amount)
        }
        else {
          amount /= 3
          amtName = (amount < 0.5 ? 'שליש ' : 'שני שליש ') + amtName.substr(5)
        }
      }


      if (amtName
          .replace('חצי','')
          .replace('רבע','')
          .replace('שני שליש','')
          .replace('שליש','')
          .replace('ורבע','')
          .replace('וחצי','')
          .replace('ושליש','')
          .replace('ושני שליש','')
          .trim() === ing.ingredient.name) {
        name = ''
      }

      return (amount > 1 ? amount+' ' : '') + amtName + ' '+name
    },

    async toggleFavorite() {
      this.favorite = !this.favorite

      if (this.favorite) {
        await Fito().RunAction('add-recipe-favorite', {recipe_id: this.recipe.id})
      }
      else {
        await Fito().RunAction('remove-recipe-favorite', {recipe_id: this.recipe.id})
      }
    }

  },

  computed : {
    recipeTags() {
      let r = []
      if (!this.recipe || !this.recipe.tags) return;
      for (let tag of this.recipe.tags) {
        const t = this.tags.find(t => t.id === tag.tag_id)
        r.push({...tag, name: t.name })
      }
      return r;
    },
    allowedTags() {
      if (!this.recipeTags) return []
      let allowed = ['פרווה','בטיחותי בהיריון','קל','כשר','צמחוני','טבעוני']
      return this.recipeTags.filter(it => allowed.indexOf(it.name) > -1)
    }
  },


}
</script>
