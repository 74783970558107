<template>
  <div class="container-m">

    <div class="panel" v-if="waitingForPaymentPage">
      <h3>{{t("One moment")}}</h3>
      <p>{{t("Transferring you to the payment page...")}}</p>
    </div>

    <SignupForm :on-submit="onSubmitDetails" :details="details" :error="error" v-if="!submittedDetails && !waitingForPaymentPage">
      <div class="centered">
        <h2>{{t("Sign Up")}}</h2>
        <p>{{t("In order to join you need to sign up first")}}</p>
      </div>
    </SignupForm>

    <ChoosePlanForm v-if="submittedDetails && token && !waitingForPaymentPage" :plans="plans" :on-cancel="cancelChoosePlan" :on-select="onChoosePlan" />
    <PoweredBy />
  </div>
</template>

<script>
import t from '@/lib/Locale';
import SignupForm from "@/components/forms/plan/SignupForm";
import Fito from "@/lib/fito";
import ChoosePlanForm from "@/components/forms/plan/ChoosePlanForm";
import PoweredBy from "@/components/ui/widgets/PoweredBy.vue";

const IS_WAR = false;

export default {
  name: "Signup",
  props: ["meta"],
  components: {PoweredBy, ChoosePlanForm, SignupForm},
  data() {
    return {
      details : {},
      plans : [],
      token: null,
      submittedDetails: false,
      waitingForPaymentPage: false,
      error: ''
    }
  },

  async mounted() {
    let token = this.$route.params && this.$route.params.code ?
        this.$route.params.code :
        localStorage.getItem('SignupToken');

    if (token) {
      this.details = await Fito().Signup.RecoverDetails(token)
      this.token = token;
    }

    this.plans = await Fito().Signup.GetPlans();

    const answers = JSON.parse(localStorage.getItem('QuizAnswers'))
    if (!token && (!answers || !answers.gender)) return this.$router.push('/')

  },

  methods : {
    t,
    async onSubmitDetails(first_name, last_name, email, password) {

      if (password.length < 8) {
        this.error = t('Your password must contain at least 8 letters')
        return false;
      }

      if (this.submittedDetails) return;
      this.submittedDetails = true
      const answers = JSON.parse(localStorage.getItem('QuizAnswers'))

      this.details = {
        first_name, last_name, email, password
      }

      const res = await Fito().Signup.Signup(first_name, last_name, email, password, answers.gender.substr(0,1), this.token);
      if (res.token) {
        localStorage.setItem('SignupToken', res.token)
        this.token = res.token;

        // Set signup stats
        const {activity, experience, pregnant, pregnancy_issues, pregnancy_multi, pregnancy_weeks} = answers
        await Fito().RunAction('set-signup-stats', {activity, experience, pregnant, pregnancy_multi, pregnancy_issues})
        if (pregnancy_weeks) await Fito().RunAction('set-pregnancy-weeks', {weeks: pregnancy_weeks})
      }
      else {
        console.error("Signup Error!", res)
        this.error = res.error;
        alert("Something went wrong, try again!")
      }
    },

    cancelChoosePlan() {
      this.submittedDetails = false
      console.log(this.details)
    },

    async onChoosePlan(plan) {
      if (IS_WAR) { // Skip getting token and just complete account registration for free, until the war is over
        await Fito().Signup.setPlan(this.token, plan.id)
        this.waitingForPaymentPage = true
        setTimeout(() => {
          window.location.href = '/plan/login?signup_success=1'


        }, 100)
        return
      }

      const tokenData = await Fito().Signup.GetTransactionToken(plan.name, this.details.first_name + ' ' + this.details.last_name, this.details.email, this.details.phone||'', plan.price, this.token)

      await Fito().Signup.setPlan(this.token, plan.id)
      this.waitingForPaymentPage = true
      setTimeout(() => {
        // console.log(tokenData.url + suffix)
        if (tokenData.url) window.location.href = tokenData.url
        else {
          this.waitingForPaymentPage = false
          console.log("Something went wrong, try again!")
        }
      }, 100)
    }
  }
}
</script>
