<template>
  <div class="panel centered">
      <p>{{t("Your subscription has expired. In order to view the plan, you will need to renew your subscription.")}}</p>
      <label>
        <button @click="trySubmit" class="btn submit">{{t('Renew Now')}}</button>
      </label>
  </div>
</template>

<script>
import t from "@/lib/Locale"
export default {
  name: "ExpiredForm",

  methods : {
    t,
    trySubmit() {
      this.$router.push("/plan/renew")
    }
  }
}
</script>
