import result1Icon from "@/assets/icons/conscious/quiz-ok.svg";
import result2Icon from "@/assets/icons/conscious/quiz-good.svg";
import result3Icon from "@/assets/icons/conscious/quiz-best.svg";

export default {
    questions: [
        { question: "האם עצרת בין ביסים?", answers: ["כן", "לא"], correctIndex: 0 },
        { question: "האם הבחנת בכמות המזון שהיתה על המזלג?", answers: ["כן", "לא"], correctIndex: 0 },
        { question: "האם נשמת לפני תחילת הארוחה?", answers: ["כן", "לא"], correctIndex: 0 },
        { question: "על מה חשבת בזמן הארוחה?", answers: ["על הארוחה עצמה", "על הקינוח שאני רוצה אחרי","על המטלות שלי","אחר"], correctIndex: 0 },
        { question: "האם עשית פעולות נוספות בנוסף לאכילה?", answers: ["צפיתי בטלויזיה", "הייתי עסוק רק בארוחה","התעסקתי בטלפון","אחר"], correctIndex: 1 },
        { question: "האם הבחנת במרקם של המנה בפה?", answers: ["כן", "לא"], correctIndex: 0 },
        { question: "האם הבחנת באיכות של המרכיבים בארוחה?", answers: ["כן", "לא"], correctIndex: 0 },
        { question: "איך אכלת?", answers: ["ישבתי בכסא ואכלתי על השולחן", "בעמידה מעל השיש","ישירות מתוך הקופסה","אחר"], correctIndex: 0 },
        { question: "מה גרם לך לאכול?", answers: ["הרגל", "אכילה מתוך רעב","סתם משעמום","הייתי צמא", "אחר"], correctIndex: 0 },
        { question: "מה היה קצב האכילה?", answers: ["אכלתי מהר", "אכלתי בצורה איטית ואיכותית", "אחר"], correctIndex: 1 },
        { question: "איך הייתה החוויה הכללית של האכילה?", answers: ["היה מעולה נהנתי וחשתי סיפוק", "את האמת שכחתי", "אחר"], correctIndex: 0 },
    ],
    results : [
        {min: 0, icon: result1Icon, title: "טובבב", description: "ממשיכים לתרגל ועולים שלב!" },
        {min: 7, icon: result2Icon, title: "יפה מאוד!!", description: "עוד קצת תרגול… ואנחנו נאלץ להכתיר אותך" },
        {min: 11, icon: result3Icon, title: "כל הכבוד!!!", description: "אנחנו מכתירים אותך - כמאסטר לאכילה מודעת!" },
    ]
}
