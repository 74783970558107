<template>
  <Hooper :rtl="true" :items-to-show="showItems" :class="clsName" :infinite-scroll="infinite" @slide="onSlide">
    <Slide v-for="(item, index) in items" :key="index" @click.native="gotoSlide(item)">
      <slot :item="item"></slot>
    </Slide>
    <HooperPagination v-if="pagination" slot="hooper-addons"></HooperPagination>
  </Hooper>
</template>

<script>

import { Hooper, Slide, Pagination as HooperPagination} from 'hooper';


export default {
  name: "Scroller",
  components: {Hooper, Slide, HooperPagination},

  props: {
    pagination: { type: Boolean, default: false},
    infinite: { type: Boolean, default: true },
    items: { type: Array },
    showItems: { type: Number, default: 1 },
    type : {type: String, default: ''},
    linkProp : {type: [String, Array], default: ''},
    linkPrefix : {type: String, default: ''},
  },

  data() {
    return {
      locked: false,
      timeout : null
    }
  },

  computed : {
    clsName() {
      if (this.type === 'small') return 'small';
      if (this.type === 'top') return 'top';
      return ''
    },
  },
  methods: {

    onSlide() {
      this.locked = true
      setTimeout(() => {
        this.locked = false
      }, 300)
    },
    gotoSlide(item) {
      let s = '';
      if (!this.locked) {
        if (this.linkProp) {
          if (this.linkProp instanceof Array) {
            for (let prop of this.linkProp) {
              if (s) s += '/'
              s += item[prop]
            }
          }
          else {
            if (typeof item[this.linkProp] !== "undefined") s += item[this.linkProp]
            else s += item
          }
          console.log(item, s)
          this.$router.push(this.linkPrefix + s);
        }
      }
    }
  }
}
</script>
