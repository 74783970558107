<template>
  <div>
    <ExpiredForm v-if="expired" />
    <div v-if="suspended">
      <div class="boxes light">
        <Box :title="t('Account Suspended')" link="/contact"><p>{{suspended}}</p></Box>
      </div>
    </div>
    <div v-if="!expired && !suspended" class="flexy col">
      <div class="padded centered">
        <h3>{{t("Welcome to your Reset plan")}}</h3>
        <p class="small" v-if="plan">{{days(daysIn)}}</p>
      </div>
      <div class="container">
        <ProgressBar :done="daysIn/21"  v-if="daysIn" />
      </div>
      <div class="menus-plan-info">
        <p>{{t('menus-plan-info-1')}}</p>
        <p>{{t('menus-plan-info-2')}}</p>
      </div>
      <div class="boxes light" v-if="!finished">
        <Box :thumb="menuIcon" :title="t('Your Menu')" link="/plan/menus/daily">{{t("Your Menu Text")}}</Box>
        <Box :thumb="instructionsIcon" :title="t('Rules and instructions')" link="/plan/menus/instructions">{{t("Rules and instructions Text")}}</Box>
      </div>
      <div v-if="finished" class="boxes">
        <div class="box">
          <div class="centered">
            <h2>{{t("You have finished this plan.")}}</h2>
            <p>{{t("Congratulations! We hope it was effective.")}}</p>
          </div>
        </div>
      </div>
      <div class="centered">
        <router-link to="/plan/menus/choose-plan" class="danger">{{t("Reset plan and choose again")}}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ExpiredForm from "@/components/forms/ExpiredForm";
import Fito from "@/lib/fito";
import t from "@/lib/Locale";
import Box from "@/components/ui/widgets/Box";
import menuIcon from '@/assets/icons/nutrition/menu.svg';
import instructionsIcon from '@/assets/icons/nutrition/instructions.svg';
import ProgressBar from "@/components/ui/widgets/ProgressBar";

export default {
  name: "MenusHome",
  components: {ProgressBar, Box, ExpiredForm},

  data() {
    return {
      menuIcon, instructionsIcon,
      expired: false,
      suspended: false,
      finished: false,
      plan : null,
      plans : null,
      daysIn : null
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')



    if (Fito().suspended) { this.suspended = Fito().suspended }

    try {
      let [m, menuPlanAge] = await Fito().RunAction('get-menus-plan', true);
      if (m && m.id) m = m.id

      if (!m) {
        return await this.$router.push('/plan/menus/choose-plan')
      }
      this.plan = m;
      const pl = await Fito().RunAction('get-menus-plans');
      this.plans = pl.data;
      this.daysIn = 1+Math.floor(menuPlanAge / 60 / 60 / 24 );

      this.finished = this.daysIn > 21

    } catch (e) {
      if (e.err_type === 'expired') {
        this.expired = true;
      }
    }
  },

  methods : {
    t,
    days(d) {
      if (d > 1) return t("{0} days out of 21", d)
      else return t("First day out of 21")
    }
  }
}
</script>
