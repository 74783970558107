<template>
  <div>
    <router-link :to="backLinkPath" class="back-link">
      <Flexy v-if="list"><img :src="ArrowRightIcon" /> <p>{{list.display_name}}</p></Flexy>
    </router-link>
    <div :class="'boxes videos vert-padded'+(hasTimeline?' timeline':'')">
      <div v-for="(item, index) in items" :key="index" :class="'box-container' + (hasTimeline && showBar(items, index) ? ' has-timeline':'')">
        <Box :title="item.title" :thumb="itemThumb(item.video)" :action-icon="itemActionIcon(item)" :link="itemLink(item)">
          <Flexy>
            <Flexy flex="1">
              <img :src="TimeIcon" class="time-icon" /> <p>{{t("Training duration: {0} minutes", item.video.duration)}}</p>
            </Flexy>
          </Flexy>
        </Box>
        <div class="timeline-bar" v-if="hasTimeline && showBar(items, index)">{{t('{0} days of rest', 2)}}</div>
      </div>
    </div>
  </div>
</template>

<script>

import t from '@/lib/Locale';
import Box from "@/components/ui/widgets/Box";
import Placeholder from '@/assets/images/training-placeholder.png';
import TimeIcon from '@/assets/icons/time.svg';
import PlayIcon from '@/assets/icons/play.svg';
import RefreshIcon from '@/assets/icons/refresh.svg';
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';
import Flexy from "@/components/ui/widgets/Flexy";
import Fito from "@/lib/fito";

export default {
  name: "YogaVideos",
  components: {Flexy, Box},
  props: ['meta'],

  data() {
    return {
      Placeholder,
      TimeIcon,
      PlayIcon,
      RefreshIcon,
      ArrowRightIcon,

      items : [],
      list: null,
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')


    await this.init();
  },

  computed : {
    backLinkPath() {
      return this.$route.meta.backLink ? this.$route.meta.backLink : '/plan/yoga'
    },
    hasTimeline() {
      return !!this.$route.meta.timeline
    },
  },

  methods : {
    t,

    showBar(items, index) {
      const preg = this.list.name.indexOf('preg') > -1
      return (preg || index > 0) && index < items.length-1
    },

    async init() {
      const videos = await Fito().Plan.getVideos()
      const mediaLists = await Fito().Plan.getMediaLists()
      const list = mediaLists.find(it => it.name === this.$route.params.list);
      if (list) {
        this.list = list;
        this.items = list.items.sort((a, b) => a.sort < b.sort?-1:1);
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].src) this.items[i].video = videos.find(it => it.id === this.items[i].src*1)
          this.items[i].completed = false
        }
      }

      if (this.meta && this.meta['completed-practices']) {
        for (let item of this.items) {
          if (this.meta['completed-practices'] && this.meta['completed-practices'] instanceof Array) {
            for (let p of this.meta['completed-practices']) if (p.practice_id*1 === item.id*1) item.completed = true;
          }
          else {
            item.completed = false;
          }
        }
      }
      else if (this.meta && this.meta['completed_yoga']) {
        for (let item of this.items) {
          if (this.meta['completed_yoga'][item.id]) {
            item.completed = true;
          }
          else {
            item.completed = false;
          }
        }
      }
    },

    itemThumb(item) {
      if (item.thumbnail) return item.thumbnail;
      return Placeholder
    },
    itemLink(item) {
      return '/plan/yoga/'+this.$route.params.list+'/'+item.id+'';
    },
    itemActionIcon(item) {
      if (item.completed) return RefreshIcon;
      return PlayIcon
    }
  },

}
</script>
