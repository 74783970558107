<template>
  <div class="container">
    <ChoosePlanForm v-if="plans.length" :plans="plans" :on-select="onChoosePlan" />
  </div>

</template>

<script>
import ChoosePlanForm from "@/components/forms/plan/ChoosePlanForm";
import Fito from "@/lib/fito";
export default {
  name: "RenewPlan",
  components: {ChoosePlanForm},
  data() {
    return {
      plans : [],
      login: null,
      details: {},
      token: ""
    }
  },
  async mounted() {
    this.login = await Fito().Login.safeCheckLogin()
    if (this.login) this.$emit('login', this.login)
    this.details = {
      first_name: this.login.subscriber.first_name,
      last_name: this.login.subscriber.last_name,
      email: this.login.subscriber.email,
      phone: this.login.subscriber.phone
    }

    this.plans = await Fito().Signup.GetPlans();
  },
  methods : {
    async onChoosePlan(plan) {

        const r = await Fito().Signup.RenewSubscriptionToken()

        if (r.token) {
            this.token = r.token;
            localStorage.setItem('SignupToken', r.token)
        }

        const tokenData = await Fito().Signup.GetTransactionToken(plan.name, this.details.first_name + ' ' + this.details.last_name, this.details.email, this.details.phone||'', 69.90, this.token)
        await Fito().Signup.setPlan(this.token, plan.id)

      this.waitingForPaymentPage = true
      setTimeout(() => {
          if (tokenData.url) window.location.href = tokenData.url
          else {
              this.waitingForPaymentPage = false
              console.log("Something went wrong, try again!")
          }
      }, 100)
    }
  }
}
</script>
