import {ApiRequest, ApiCall} from '../lib/Api';
import ApiRoutes from "../lib/ApiRoutes";

const cache = {}


export default class Plan {

    constructor(fito) {
        this.fito = fito;
    }

    ResetPlanCache() {
        console.log("Resetting plan cache")
        cache.mediaLists = cache.videos = cache.exclusives = null
    }

    async Log(type, details = '') {
        const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.log, {type, details}), this.fito.ApiKey, this.fito.token)
        if (r.res === "error") throw r.error
        return r;
    }

    async ChangePassword(password, password2) {
        const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.changePassword, {password, password2}), this.fito.ApiKey, this.fito.token)
        if (r.res === "error") throw r.error
        return r;
    }


    async UpdateAccountDetails(first_name, last_name, email) {
        const data = {first_name, last_name, email}
        const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.updateAccountDetails, data), this.fito.ApiKey, this.fito.token)
        if (r.res === "error") throw r.error
        return r;
    }

    async CancelSubscription(cancellation_reason) {
        const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.cancelSubscription, {cancellation_reason}), this.fito.ApiKey, this.fito.token)
        if (r.res === "error") throw r.error
        return true
    }

    async getVideos() {
        if (cache.videos) return cache.videos;
        const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.getVideos), this.fito.ApiKey, this.fito.token)
        if (r.res === "error") throw r.error
        cache.videos = r;
        return r;
    }

    async getMediaLists() {
        if (cache.mediaLists) return cache.mediaLists;
        const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.getMediaLists), this.fito.ApiKey, this.fito.token)
        if (r.res === "error") throw r.error
        cache.mediaLists = r;
        return r;
    }

    async getExclusives() {
        if (cache.exclusives) return cache.exclusives;
        const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.getExclusives), this.fito.ApiKey, this.fito.token)
        if (r.res === "error") throw r.error
        cache.exclusives = r.exclusives ? r.exclusives : r;
        return r.exclusives ? r.exclusives : r;
    }

}
