<template>
  <svg class="phone-audio-bars">
    <rect rx="4" :height="20*height" width="4" :x="i*6" :y="10-(height*10)" v-for="(height, i) in heights" :key="i" fill="#4a4a4a" />
  </svg>
</template>

<script>
export default {
  name: "PhoneAudioBars",
  data() {
    return {
      heights:[]
    }
  },
  mounted() {
    for (let i = 0; i < 156; i++) {
      this.heights.push(0.2+Math.random()*0.8)
    }
  }
}
</script>
