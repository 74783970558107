import Fito from './src/Fito'
const DEFAULT_HOSTNAME = 'https://brands.fito.ai'

let instance = null;
export async function initFito(apiKey, hostname = DEFAULT_HOSTNAME, clientVersion = null, onOldVersion = null) {
        instance = new Fito(apiKey, hostname);
        try {
            await instance.initApi(apiKey, clientVersion, onOldVersion);
            return true
        } catch (e) {
            console.log("FITO INIT ERROR!", e);
        }
}

export default function() { return instance; }
