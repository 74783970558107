import MaleIcon from "@/assets/icons/quiz/gender-male.png";
import FemaleIcon from "@/assets/icons/quiz/gender-female.png";
import BabyIcon from "@/assets/icons/quiz/baby.png";
import Babies1Icon from "@/assets/icons/quiz/babies-one.png";
import Babies2Icon from "@/assets/icons/quiz/babies-two.png";
import HappyIcon from "@/assets/icons/quiz/happy.png";
import HeartHealthIcon from "@/assets/icons/quiz/heart-health.png";
import FitnessActiveIcon from "@/assets/icons/quiz/fitness-active.png";
import LazyIcon from "@/assets/icons/quiz/fitness-inactive.png";
import YogaExp1Icon from "@/assets/icons/quiz/yoga-1.png";
import YogaExp2Icon from "@/assets/icons/quiz/yoga-2.png";

export default [
    {
        prop: "gender",
        tab: "Your Gender",
        tabMobile: "Gender",
        question: "What is your Gender?",
        info: "",
        type: "squares",
        answers: [
            { value: "male", label: "Male", icon : MaleIcon },
            { value: "female", label: "Female", icon : FemaleIcon },
        ]
    },

    {
        condition: (ans) => ans.gender === 'female',
        prop: "pregnancy",
        tab: "Pregnancy",
        tabMobile: "Pregnancy",
        question: "Are you pregnant?",
        info: "",
        type: "squares",
        answers: [
            { value: "yes", label: "Yes", icon : BabyIcon },
            { value: "no", label: "No", icon : HappyIcon },
        ]
    },

    {
        condition: (ans) => ans.gender === 'female' && ans.pregnancy === "yes",
        prop: "pregnancy_weeks",
        tab: "Pregnancy Weeks",
        tabMobile: "Pregnancy Weeks",
        question: "Congratulations! what week are you in?",
        info: "Choose a week",
        type: "slider",
        min: 1,
        max: 40
    },

    {
        condition: (ans) => ans.gender === 'female' && ans.pregnancy === "yes",
        prop: "pregnancy_multi",
        tab: "How many babies",
        tabMobile: "How many babies",
        question: "Does this pregnancy have more than one baby?",
        type: "squares",
        answers: [
            { value: "no", label: "No", icon : Babies1Icon },
            { value: "yes", label: "Yes", icon : Babies2Icon },
        ]
    },


    {
        condition: (ans) => ans.gender === 'female' && ans.pregnancy === "yes",
        prop: "pregnancy_issues",
        tab: "Pregnancy issues",
        tabMobile: "Pregnancy issues",
        question: "Are you suffering from diabetes or Preeclampsia?",
        type: "squares",
        answers: [
            { value: "yes", label: "Yes", icon : HeartHealthIcon },
            { value: "no", label: "No", icon : HappyIcon },
        ]
    },

    {
        prop: "activity",
        tab: "Activity Level",
        tabMobile: "Activity Level",
        question: "Are you doing sport activity regularly?",
        answers: [
            { value: "yes", label: "Yes", icon : FitnessActiveIcon },
            { value: "no", label: "No", icon : LazyIcon },
        ]
    },

    {
        prop: "experience",
        tab: "Experience",
        tabMobile: "Experience",
        question: "Did you practise yoga before?",
        answers: [
            { value: "yes", label: "Yes", icon : YogaExp1Icon },
            { value: "few", label: "A few times", icon : YogaExp2Icon },
            { value: "no", label: "No", icon : LazyIcon },
        ]
    },

]
