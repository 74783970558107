<template>
  <div>
    <div class="panel raw">
      <h3>{{t("I'm allergic to:")}}</h3>
      <label v-for="(cat, ii) in allergies" :key="ii">
        <input type="checkbox" @change="(e) => onClick(e, cat.name, true)" :checked="checkedAllergies.indexOf(cat.name) > -1" /><span>{{cat.name}}</span>
      </label>
    </div>
    <div class="panel raw">
      <h3>{{t("Kitchen Tools:")}}</h3>
      <label v-for="(cat, ii) in cookingTools" :key="ii">
        <input type="checkbox" @change="(e) => onClick(e, cat.id)" :checked="checked.indexOf(cat.id) > -1"  /><span>{{cat.name}}</span>
      </label>
    </div>
    <div class="panel raw">
      <h3>{{t("Preparation Methods:")}}</h3>
      <label v-for="(cat, ii) in cookingMethods" :key="ii">
        <input type="checkbox" @change="(e) => onClick(e, cat.id)" :checked="checked.indexOf(cat.id) > -1" /><span>{{cat.name}}</span>
      </label>
    </div>

  </div>
</template>

<script>

import t from "@/lib/Locale";
import {allergies} from "@/lib/RecipeUtils";

const cookingTools = ['מיקסר/בלנדר','תנור','כיריים'],
    cookingMethods = ['סיר אחד','מגש אחד']

export default {
  name: "SearchFilters",
  props: {
    filters: {type: Object},
    tags: { type: Array },
    categories: { type: Array },
  },

  data() {
    return {
      allergies,

      checked: [],
      checkedAllergies: [],
    }
  },

  mounted() {

  },

  computed : {
    cookingTools() { return this.tags.filter(tag => cookingTools.indexOf(tag.name) > -1) },
    cookingMethods() { return this.tags.filter(tag => cookingMethods.indexOf(tag.name) > -1) },
  },

  methods : {
    t,
    onClick(e, cat, isAllergy) {
      const checked = e.target.checked
      if (isAllergy) {
        if (checked) {
          this.checkedAllergies.push(cat)
        }
        else {
          this.checkedAllergies = this.checkedAllergies.filter(it => it !== cat)
        }
      }
      else {
        if (checked) {
          this.checked.push(cat)
        }
        else {
          this.checked = this.checked.filter(it => it !== cat)
        }
      }

      this.$emit('change', this.checked, this.checkedAllergies)
    }
  },
  watch : {
    filters() {
      this.checkedAllergies = []
      this.checked = []
      if (this.filters) {
        for (let allergy of this.filters.allergies) this.checkedAllergies.push(allergy)
        for (let tag of this.filters.tags) this.checked.push(tag)
      }
    }
  }
}
</script>
