<template>
  <div class="container-m my-profile">
    <div class="success" v-if="success">{{success}}</div>
    <Flexy class="profile-meta">
        <img :src="AvatarImage" />
        <div>
          <h2>{{first_name + ' '+last_name}}</h2>
          <p>{{email}}</p>
        </div>
    </Flexy>
    <div class="panel raw">
      <InputWrapper :label="t('First Name')" type="name" v-model="first_name" :placeholder="t('First Name')" />
      <InputWrapper :label="t('Last Name')" type="name" v-model="last_name" :placeholder="t('Last Name')" />
      <InputWrapper :label="t('E-mail *')" type="email" v-model="email" :placeholder="t('E-mail Address')" />
      <Flexy>
        <button class="btn medium" @click="updateSubscriberDetails">{{t("Save Changes")}}</button>
        <button class="btn medium secondary" @click="resetSubscriberDetails">{{t("Reset")}}</button>
      </Flexy>
    </div>

    <div class="panel raw">
      <InputWrapper :label="t('New Password')" type="password" v-model="newPassword" :placeholder="t('New Password')" />
      <InputWrapper :label="t('Confirm New Password')" type="password2" v-model="newPassword2" :pass1="newPassword" :placeholder="t('Confirm New Password')" />
      <Flexy>
        <button :class="'btn medium'+(canSavePassword?'':' disabled')" @click="updatePassword">{{t("Update Password")}}</button>
      </Flexy>
    </div>

    <div class="panel raw" v-if="login.subscriber && login.subscriber.gender === 'f'">
      <label>{{t("Status")}}</label>
      <div class="btn-group">
        <div :class="'btn'+(isPregnant?'':' active')" @click="isPregnant = false">{{t("Normal")}}</div>
        <div :class="'btn'+(isPregnant?' active':'')" @click="isPregnant = true">{{t("Pregnancy")}}</div>
      </div>
        <div v-if="isPregnant">
          <label>
            <input type="range" max="40" :value="pregWeek" @input="onPregWeekChange" @change="onPregWeekChange">
          </label>
          <div class="centered">{{t("Week: {0}", pregWeek)}}</div>
        </div>
      <div class="input-wrapper" v-if="hasPregStatusChange">
        <input type="checkbox" @change="confirmPregStatusChange" :checked="statusPregChangeConfirmed" /> {{t("Confirm pregnancy status change")}}
      </div>
      <Flexy>
        <button :class="'btn medium'+(!hasPregStatusChange || statusPregChangeConfirmed?'':' disabled')" @click="setPregnancyStatus">{{t("Save Changes")}}</button>
      </Flexy>
    </div>

    <div class="panel raw" v-if="login.subscriber && ((login.transactions && login.transactions.length) || (login.charges && login.charges.length))">
      <label>{{t("Receipts From Us")}}</label>
      <div class="boxes search-results">
        <Box v-for="(transaction) in login.transactions" :key="transaction.id">
          <Flexy :between="true">
            <span>{{parseTransactionDate(transaction.date)}}</span>
            <span>{{t("{0} nis", transaction.amount)}}</span>
            <button class="btn small" @click="() => getInvoice(transaction.id)">{{t("Get Invoice")}}</button>
          </Flexy>
        </Box>
        <Box v-for="(charge) in login.charges" :key="charge.id">
          <Flexy :between="true">
            <span>{{parseTransactionDate(charge.date)}}</span>
            <span>{{t("{0} nis", charge.amount)}}</span>
            <button class="btn small" @click="() => getInvoice(charge.id, true)">{{t("Get Invoice")}}</button>
          </Flexy>
        </Box>
      </div>
    </div>

    <div class="panel raw" v-if="login.subscriber">
      <label>{{t("Sign up date")}}</label>
      <label>{{signupDate}}</label>
      <div v-if="!expired">
        <label>{{t(login.renew?"Renewal date":"Account expiry date")}} </label>
        <label>{{renewalDate}}</label>
      </div>
      <div v-if="expired">
        <router-link to="/plan/renew" class="btn">{{t("Renew Now")}}</router-link>
      </div>
      <label class="border-top" v-if="login.renew">
        <a class="btn medium danger" @click="toggleCancelSubscription" v-if="!cancelSubscriptionDialog">{{t("Cancel Subscription")}}</a>
        <div v-if="cancelSubscriptionDialog">
          <h3>{{t("Are you sure?")}}</h3>
          <p>{{t("Canceling your subscription will allow you to use your account until the next billing period")}}</p>
          <div class="cancel-reason">
            <h3>{{ t("Please select the reason you want to cancel your subscription") }}</h3>
            <div><input type="radio" name="cancel-reason" v-model="cancelReason" value="It is too expensive for me" @change="onCancelReason" /><span>{{t("It is too expensive for me")}}</span></div>
            <div><input type="radio" name="cancel-reason" v-model="cancelReason" value="I was not satisfied with the content" @change="onCancelReason" /><span>{{t("I was not satisfied with the content")}}</span></div>
            <div><input type="radio" name="cancel-reason" v-model="cancelReason" value="I did not want a monthly subscription" @change="onCancelReason" /><span>{{t("I did not want a monthly subscription")}}</span></div>
            <div><input type="radio" name="cancel-reason" v-model="cancelReason" value="Other" @change="onCancelReason" /><span>{{t("Other")}}</span></div>
            <input v-if="cancelReason==='Other'" type="text" @change="onCancelOtherReason" />
          </div>
          <div class="btn-group">
            <a :class="'btn primary' + (this.canCancel?'':' disabled')" @click="doCancelSubscription">{{t('Yes')}}</a>
            <a class="btn secondary" @click="toggleCancelSubscription">{{t('No')}}</a>
          </div>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import t from '@/lib/Locale';
import Fito from '@/lib/fito';
import Flexy from "@/components/ui/widgets/Flexy";
import AvatarImage from '@/assets/images/avatar.svg';
import InputWrapper from "@/components/ui/widgets/InputWrapper";
import Box from "@/components/ui/widgets/Box";


export default {
  name: "MyProfile",
  components: {Box, InputWrapper, Flexy},
  data() {
    return {
      AvatarImage,
      newPassword: '',
      newPassword2 : '',

      first_name: '',
      last_name: '',
      email: '',

      pregWeek: 1,
      isPregnant: false,
      statusPregChangeConfirmed: false,

      login : {},
      expired: false,
      success: '',

      cancelled : false,
      cancelReason : '',
      cancelOtherReason : '',
      dTimeout:null,
      cancelSubscriptionDialog: false,
    }
  },
  async mounted() {
    const login = await Fito().Login.safeCheckLogin();
    if (!login) await this.$router.push('/plan/login');

    this.login = login
    this.expired = login.status === 'expired'
    this.first_name = login.subscriber.first_name
    this.last_name = login.subscriber.last_name
    this.email = login.subscriber.email

      if (login.meta['pregnancy-weeks']) {
        this.isPregnant = true
        this.pregWeek = login.meta['pregnancy-weeks']*1
    }
  },

  methods: {
    t,

    onCancelReason(e) {
      this.cancelReason   = e.target.value
    },

    onCancelOtherReason(e) {
      this.cancelOtherReason = e.target.value
    },

    onPregWeekChange(e) {
      this.pregWeek = e.target.value*1
    },
    confirmPregStatusChange(e) {
      this.statusPregChangeConfirmed = e.target.checked
    },
    async updateSubscriberDetails() {

      await Fito().Plan.UpdateAccountDetails(this.first_name, this.last_name, this.email)
      this.showSuccess(t("Successfully updated subscriber details"))
    },
    resetSubscriberDetails() {
      this.first_name = this.login.subscriber.first_name
      this.last_name = this.login.subscriber.last_name
      this.email = this.login.subscriber.email
    },

    async getInvoice(transactionId, isCharge) {
      if (isCharge) {
        await Fito().RunAction('cardcom-get-charge-invoice-pdf', { charge_id: transactionId })
      }
      else await Fito().RunAction('cardcom-get-invoice-pdf', { transaction_id: transactionId })
    },

    async updatePassword() {
      if (this.canSavePassword) {
        await Fito().Plan.ChangePassword(this.newPassword, this.newPassword2)
        this.newPassword = this.newPassword2 = '';
        this.showSuccess(t("Successfully reset password"))
      }
    },

    async setPregnancyStatus() {
      if (this.hasPregStatusChange && !this.statusPregChangeConfirmed) return;

      if (this.isPregnant) {
        await Fito().RunAction('set-pregnancy-weeks', {weeks: this.pregWeek})
      }
      else {
        await Fito().RunAction('clear-pregnancy-weeks')
      }

      this.showSuccess(t("Successfully set pregnancy status"))
    },

    showSuccess(message) {
      this.success = message;
      setTimeout(() => this.success = '', 5000)
    },

    toggleCancelSubscription(e) {
      this.cancelSubscriptionDialog = !this.cancelSubscriptionDialog
      e.stopPropagation()
      return false
    },

    async doCancelSubscription() {
      if (!this.canCancel) return
      await Fito().Plan.CancelSubscription(this.cancelReason === 'Other' && this.cancelOtherReason?this.cancelOtherReason:this.cancelReason)
      this.login.renew = false
      this.cancelSubscriptionDialog = false

      await Fito().Plan.Log("cancel-subscription", this.cancelReason === 'Other' && this.cancelOtherReason?this.cancelOtherReason:this.cancelReason)

      this.showSuccess(t("Successfully cancelled subscription"))
      this.login = await Fito().Login.safeCheckLogin(true)

    },

    parseTransactionDate(date) {
      let d = new Date(date)
      return d.getDate()+'/'+(d.getMonth()+1)+'/'+(d.getFullYear())
    }
  },

  computed : {

    canSavePassword() { return this.newPassword === this.newPassword2 && this.newPassword.length >= 8 },
    canCancel() {
      return this.cancelReason.length && (this.cancelReason !== "Other" || this.cancelOtherReason)
    },
    signupDate() {
      if (this.login.subscriber.subscription_date) {
        const date = new Date(this.login.subscriber.subscription_date);
        return date.getDate() + '/' + (date.getMonth()+1) + '/' + date.getFullYear()
      }
      return '';
    },
    renewalDate() {
      let date;
      if (this.login.subscriber.expiration_date) {
        date = new Date(this.login.subscriber.expiration_date);
        return date.getDate() + '/' + (date.getMonth()+1) + '/' + date.getFullYear()
      }
      else if (this.login.subscriber.subscription_date) {

        const interval = this.login.subscriber.charge_interval || 1

        date = new Date(this.login.subscriber.subscription_date);
        while (date.getTime() < new Date().getTime()) {
          date = new Date(date.getTime() + (1000*60*60*24*30) * interval)
          if (!this.login.renew) break;
        }
        return date.getDate() + '/' + (date.getMonth()+1) + '/' + date.getFullYear()
      }
      return '';
    },
    hasPregStatusChange() {
      const original = this.login.meta['pregnancy-weeks'];
      console.log(this.isPregnant, original)
      return !!this.isPregnant !== !!original;
    }
  }
}
</script>
