<template>
  <div class="stages-bar-container">
    <ul class="stages-bar">
<!--      <li v-for="(question, index) in questions" :key="index" :class="'stage' + (index <= quizIndex ? ' active': '')" @click="onClick(index)">-->
      <li>
        <span class="desktop-only">{{t(questions[quizIndex].tab)}}</span>
        <span class="mobile-only">{{t(questions[quizIndex].tabMobile)}}</span>
<!--        <img :src="CheckIcon" :class="'check' + (index < quizIndex ? ' active': '')" />-->
      </li>
<!--      </li>-->
    </ul>
    <ProgressBar :done="quizIndex/(questions.length)" />
  </div>
</template>

<script>

import ProgressBar from "@/components/ui/widgets/ProgressBar";
const stages = [ "Your Gender", "Your Goal", "Activity Level", "Weekly Workouts", "Complete Details" ];
import CheckIcon from "@/assets/icons/check.svg";
import t from "@/lib/Locale"

export default {
  name: "StagesBar",
  components: {ProgressBar},
  props: ['quizIndex', 'questions', 'onChange'],

  mounted() {
    this.setIndicatorWidth()
  },

  computed : {
    question() { return this.questions[this.quizIndex] }
  },

  created() {
    window.addEventListener("resize", this.setIndicatorWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.setIndicatorWidth);
  },

  methods : {
    t,
    setIndicatorWidth() {
      let w = 0;
      let x = (document.querySelector(".stages-bar"));
      if (x) {
        this.indicatorWidth = (x.offsetWidth / (this.questions.length/(this.quizIndex+1)))+'px';
        return;

        // const el = (document.querySelectorAll(".stages-bar .stage"))[this.quizIndex];
        // if (el) w = ((x.offsetWidth+100) - el.offsetLeft + el.offsetWidth - x.offsetLeft)+'px';
      }

      this.indicatorWidth = w;
    },
    onClick(index) {
      if (index < this.quizIndex) this.onChange(index)
    }
  },

  data() {
    return {
      CheckIcon,
      stages,
      indicatorWidth : 0
    }
  },

  watch : {
    quizIndex() {
      this.setIndicatorWidth()
    }
  }
}
</script>
