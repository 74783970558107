<template>
  <div :class="clsName">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Flexy",
  props : ['between','even', 'flex'],
  computed : {
    clsName() {
      let flx = typeof this.flex !== 'undefined' ? this.flex*1 : null
      return 'flexy'
          +(this.between?' between':'')
          +(this.even?' even':'')
          +(flx != null ?' flex-'+flx:'')
    }
  },
}
</script>
