<template>
  <div class="item-view">
    <div class="item-jumbo fixed" :style="{backgroundImage: `url(${item.thumbnail})`}" v-if="item">
    </div>
    <div class="item-details" v-if="item">
      <h2>{{item.title}}</h2>
      <div>{{item.headline}}</div>
      <ul class="more-info primary">
        <li v-if="item.details" @click="toggleDetails">
          <Flexy :between="true" :flex="1">
            <span>{{t("Want to know more?")}}</span>
            <a>{{showDetails?'-':'+'}}</a>
          </Flexy>
        </li>
        <li v-if="showDetails">
          {{item.details}}
        </li>
        <li v-if="item.conditions" @click="toggleConditions">
          <Flexy :between="true" :flex="1">
            <span>{{t("Exclusive conditions")}}</span>
            <a>{{showConditions?'-':'+'}}</a>
          </Flexy>
        </li>
        <li v-if="showConditions">
          {{item.conditions}}
        </li>
        <a v-if="item.url" :href="item.url" target="_blank" class="item-link">{{t("For more details in the company website")}} <img :src="ExternalLinkIcon" /></a>
      </ul>

      <button class="btn" v-if="item.code" @click="toggleCodeModal">{{t("Show benefit code")}}</button>


      <Modal v-if="showCode" :dismiss="toggleCodeModal" class="centered">
        <p>{{t("The code is:")}}</p>
        <h1>{{item.code.toUpperCase()}}</h1>
        <small v-if="item.code_validity">{{t("The code is valid until {0}", item.code_validity)}}</small>
      </Modal>
    </div>
  </div>
</template>

<script>

import Fito from '@/lib/fito';

import t from "@/lib/Locale";
import Flexy from "@/components/ui/widgets/Flexy";
import Modal from "@/components/ui/widgets/Modal";
import ExternalLinkIcon from '@/assets/icons/external-link.svg';


const ITEMS_IN_TOP_SLIDE = 1;

export default {

  name: "ExclusivesItem",
  components: {Modal, Flexy},
  data : function() {
    return {
      ExternalLinkIcon,

      item : null,
      showDetails: false,
      showConditions: false,
      showCode: false
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')

    if (login.status === "expired") { // expired users don't get discounts?
      return this.$router.push('/plan/home')
    }

    this.items = await Fito().Plan.getExclusives()
    this.locked = false

    this.item = this.items.find(it => it.id === this.$route.params.id * 1)

    await Fito().Plan.Log("view-exclusive", this.item.title)
  },

  computed : {
    topItems() {
      return this.items.slice(0, ITEMS_IN_TOP_SLIDE)
    },
    restOfItems() {
      return this.items.slice(ITEMS_IN_TOP_SLIDE - Math.max(0, ITEMS_IN_TOP_SLIDE-this.items.length))
    }
  },

  methods: {
    t,
    toggleCodeModal() {
      this.showCode = !this.showCode;
    },
    toggleDetails() {
      this.showDetails = !this.showDetails
    },
    toggleConditions() {
      this.showConditions = !this.showConditions

    }
  },

}
</script>
