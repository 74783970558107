<template>
  <div>
    <router-link to="/plan/conscious" class="back-link">
      <Flexy v-if="list"><img :src="ArrowRightIcon" /> <p>{{list.display_name}}</p></Flexy>
    </router-link>
    <div class="boxes videos vert-padded" v-if="list && list.media_type === 'video'">
      <Box :title="item.title" :thumb="itemThumb(item.video)" :action-icon="PlayIcon" :link="itemLink(item)" v-for="(item, index) in items" :key="index">
        <Flexy>
          <Flexy flex="1">
            <img :src="TimeIcon" class="time-icon" /> <p>{{t("Training duration: {0} minutes", Math.ceil(item.video.duration/60))}}</p>
          </Flexy>
        </Flexy>
      </Box>
    </div>
    <div class="boxes tips vert-padded" v-if="list && list.media_type === 'image'">
      <Hooper :rtl="true" :items-to-show="1" :style="{height: 'calc(100vh - 170px)'}">
        <Slide v-for="(item, index) in items" :key="index">
          <Box>
            <img v-if="item.src" :src="item.src" />
            <h2>{{item.title}}</h2>
            <p>{{item.content}}</p>
          </Box>
        </Slide>
        <HooperPagination slot="hooper-addons"></HooperPagination>

      </Hooper>
    </div>
  </div>
</template>

<script>

import t from '@/lib/Locale';
import Box from "@/components/ui/widgets/Box";
import Placeholder from '@/assets/images/training-placeholder.png';
import TimeIcon from '@/assets/icons/time.svg';
import PlayIcon from '@/assets/icons/play.svg';
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';
import Flexy from "@/components/ui/widgets/Flexy";
import Fito from "@/lib/fito";
import { Hooper, Slide, Pagination as HooperPagination} from 'hooper';




export default {
  name: "ConsciousMediaList",
  components: {Flexy, Box, Hooper, Slide, HooperPagination},
  props: ['meta'],

  data() {
    return {
      Placeholder,
      TimeIcon,
      PlayIcon,
      ArrowRightIcon,

      items : [],
      list: null,
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')


    const videos = await Fito().Plan.getVideos()
    const mediaLists = await Fito().Plan.getMediaLists()
    const list = mediaLists.find(it => it.name === this.$route.params.list);
    if (list) {
      this.list = list;
      this.items = list.items;
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].src) this.items[i].video = videos.find(it => it.id === this.items[i].src*1)
      }
    }

  },

  computed : {
  },

  methods : {
    t,
    itemThumb(item) {
      if (item.thumbnail) return item.thumbnail;
      return Placeholder
    },
    itemLink(item) {
      return '/plan/conscious/videos/'+item.id+'';
    },
  }

}
</script>
