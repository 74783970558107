<template>
  <div class="powered-by">
    Powered by FIT Operations LTD
  </div>
</template>

<script>
export default {
  name: "PoweredBy"
}
</script>
