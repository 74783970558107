<template>
  <div class="member-menu">
      <ul class="items">
        <li><router-link to="/plan/yoga">{{t("Yoga Trainings")}}</router-link></li>
        <li><router-link to="/plan/recipes">{{t("Recipes")}}</router-link></li>
        <li><router-link to="/plan/conscious">{{t("Conscious Eating")}}</router-link></li>
        <li><router-link to="/plan/exclusives">{{t("Exclusives")}}</router-link></li>
      </ul>
  </div>
</template>

<script>
import t from '@/lib/Locale';

export default {
  name: "MemberMenu",
  data() {
    return {
    }
  },
  methods :{
    t,
  }
}
</script>
