<template>
  <div>
    <div class="boxes quiz-questions">
      <div v-for="(q, i) in questions" :key="i" class="box">
        <p>{{q.question}}</p>
        <div class="btn-group">
          <button :class="btnClass(i, ii)" v-for="(a, ii) in q.answers" :key="ii" @click="pickAnswer(i, ii)">{{a}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConsciousQuizQuestions",
  props: {
    questions: {type: Array },
    onResults: {type: Function },
  },
  data() {
    return {
      answers : []
    }
  },
  methods : {
    pickAnswer(questionIndex, answerIndex) {
      this.$set(this.answers, questionIndex,answerIndex);

      let ok = this.answers.filter(it => !isNaN(it)).length

      if (ok === this.questions.length) { // answered everything
        this.finishQuiz()
      }

    },

    finishQuiz() {
      let score = 0;
      for (let q = 0; q < this.questions.length; q++) {
        if (this.answers[q] === this.questions[q].correctIndex) score++
      }
      this.onResults(score)
    },

    btnClass(questionIndex, answerIndex) {
      return 'btn' + (this.answers[questionIndex] === answerIndex ? ' active':'');
    }
  }
}
</script>
