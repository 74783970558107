<template>
  <div class="container-m">
    <router-link to="/plan/menus/daily" class="back-link">
      <Flexy><img :src="ArrowRightIcon" /> <p>{{t("Select Meal - {0}",planMeal)}}</p></Flexy>
    </router-link>
    <SearchInput :value="search" :placeholder="t('Search for a recipe')" @focus="onSearchFocus" @blur="onSearchBlur" @change="onSearchInput" @filters="onSearchFilter" />
    <div class="boxes scrolling large">
      <Box :thumb="getThumb(recipe)" v-for="(recipe, ii) in mealRecipes" :key="ii" :link="'/plan/menus/meal-details/'+recipe.id">
        <h3>{{recipe.name}}</h3>
        <Flexy class="p">
          <img :src="TimeIcon" />
          <p>{{t("Prep time: {0} minutes", recipe.prep_time)}}</p>
          <p v-for="(tag, ii) in allowedTags(recipe)" :key="ii">{{tag.name}}</p>
        </Flexy>
      </Box>
      <div v-if="plan && !mealRecipes.length">{{t("Didn't find matching meals. Please narrow your search!")}}</div>
    </div>
  </div>
</template>

<script>
import Flexy from "@/components/ui/widgets/Flexy";
import Box from "@/components/ui/widgets/Box";
import t from "@/lib/Locale";
import RecipePlaceholder from "@/assets/images/recipe-placeholder.png";
import TimeIcon from "@/assets/icons/time.svg";
import ArrowRightIcon from "@/assets/icons/arrow-right.svg";
import Fito from "@/lib/fito";
import {applySavedFilters} from "@/lib/RecipeUtils";
import SearchInput from "@/components/ui/widgets/SearchInput";
export default {
  name: "MenusSelectMeal",
  components: {SearchInput, Box, Flexy},
  data : function() {
    return {
      TimeIcon, ArrowRightIcon,
      recipes : [],
      categories : [],
      tags: [],
      planMeal: '',
      plan: null,
      baseTag: null,
      search : ""
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')



    this.planMeal = localStorage.getItem('meal-plan-meal')
    this.baseTag = localStorage.getItem('meal-plan-tag')*1

    this.recipes = await Fito().RunAction('get-recipes');
    this.categories = await Fito().RunAction('get-recipe-categories')
    this.tags = await Fito().RunAction('get-recipe-tags')

    this.recipes = applySavedFilters(this.recipes)
    const plans = await Fito().RunAction('get-menus-plans');
    const plan = await Fito().RunAction('get-menus-plan');
    let planId = plan.id ? plan.id : plan

    this.plan = plans.find(it => it.id*1 === planId*1)
  },
  methods: {
    t,
    getThumb(recipe) {
      return recipe.images && recipe.images.length ? recipe.images[0].image : RecipePlaceholder
    },
    recipeTags(recipe) {
      let r = []
      for (let tag of recipe.tags) {
        const t = this.tags.find(t => t.id === tag.tag_id)
        if (t) r.push({...tag, name: t.name })
      }
      return r;
    },
    allowedTags(recipe) {
      if (!this.recipeTags(recipe).length) return []
      let allowed = ['פרווה','בטיחותי בהיריון','קל','כשר','צמחוני','טבעוני']
      return this.recipeTags(recipe).filter(it => allowed.indexOf(it.name) > -1)
    },
    onSearchInput(search) {
      this.search = search;
    },
    onSearchFilter() {
      this.$router.push('/plan/menus/meal-filters')
    },
    onSearchFocus() {
      this.searchFocus = true
    },
    onSearchBlur() {
      setTimeout(() => {
        this.searchFocus = false
      }, 300)
    },
  },

  computed : {
    mealRecipes() {

      let recipes = this.recipes;

      if (this.plan && this.plan.limit_categories) {
        const categories = this.plan.limit_categories.trim().split(/,/g).map(it => it.trim()).map(c => this.categories.find(cat => cat.name === c)).map(it => it.id)
        if (categories.length > 0) recipes = recipes.filter(r => {
          for (let c of r.categories) if (categories.indexOf(c.category_id) > -1) return true
        })
      }


      if (this.plan && this.plan.limit_tags) {
        const tags = this.plan.limit_tags.trim().split(/,/g).map(it => it.trim()).map(t => this.tags.find(tag => tag.name === t)).map(it => it.id)
        if (tags.length > 0) recipes = recipes.filter(r => {
          for (let c of r.tags) {
            if (tags.indexOf(c.tag_id) > -1) return true
          }
        })
      }

      return recipes.filter( it =>
          it.tags.filter(it => it.tag_id === this.baseTag).length &&
          it.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
    },
    category() {
      const categoryId = this.$route.params.id*1;
      return this.categories.find(it => it.id === categoryId)
    },
  },
}
</script>
