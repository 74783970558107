<template>
  <div class="questions">
    <ul class="questions">
      <li v-for="(item, index) in items" :key="index">
        <div class="question" @click="toggle(index)">
          <Flexy :between="true" flex="1">
            <span>{{item.question}}</span>
            <a>{{open[index]?'-':'+'}}</a>
          </Flexy>
        </div>
        <div class="answer" v-if="isOpen(index)">{{item.answer}}</div>
      </li>
    </ul>
  </div>
</template>

<script>

import Flexy from "@/components/ui/widgets/Flexy";
export default {
  name: "Qa",
  components: {Flexy},
  props: {
    items: { type: Array },
  },

  data() {
    return {
      open: []
    }
  },

  computed : {

  },
  methods: {
    toggle(index) {
      this.$set(this.open, index, !this.open[index]);
    },
    isOpen(index) {
      return !!(this.open[index])
    }
  }
}
</script>
