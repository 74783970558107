<template>
  <div class="item-view">
    <div class="item-jumbo">
      <div class="jumbo-overlay">
        <router-link class="btn" to="/plan/yoga"><img :src="ArrowRightIcon"></router-link>
      </div>
      <Vimeo :is-event="true" :path="vidId" />
    </div>
    <div class="item-details">
      <h2>{{t("Live Practice")}}</h2>
    </div>
    <div :class="'boxes videos vert-padded'">
      <div v-for="(item, index) in items" :key="index" :class="'box-container' + (hasTimeline && showBar(items, index) ? ' has-timeline':'')">
        <Box :title="item.title" :thumb="itemThumb(item.video)" :action-icon="itemActionIcon(item)" :link="itemLink(item)">
          <Flexy>
            <Flexy flex="1">
              <img :src="TimeIcon" class="time-icon" /> <p>{{t("Training duration: {0} minutes", item.video.duration)}}</p>
            </Flexy>
          </Flexy>
        </Box>
      </div>
    </div>
  </div>
</template>

<script>

import t from '@/lib/Locale';
import Fito from "@/lib/fito";
import CheckCircleIcon from '@/assets/icons/check-circle.svg';
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';
import HeartIcon from '@/assets/icons/heart.svg';
import HeartFullIcon from '@/assets/icons/heart-full.svg';
import Vimeo from "@/components/ui/widgets/Vimeo";
import Placeholder from "@/assets/images/training-placeholder.png";
import RefreshIcon from "@/assets/icons/refresh.svg";
import PlayIcon from "@/assets/icons/play.svg";
import TimeIcon from "@/assets/icons/time.svg";
import Box from "@/components/ui/widgets/Box";
import Flexy from "@/components/ui/widgets/Flexy";

export default {
  name: "LivePractice",
  components: {Flexy, Box, Vimeo},
  props: ['meta'],
  data() {
    return {
      CheckCircleIcon, ArrowRightIcon, HeartIcon, HeartFullIcon,
      TimeIcon,
      PlayIcon,
      RefreshIcon,
      completed: false,
      items: []
    }
  },

  async mounted() {

    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')


    await this.init();
  },

  computed : {
    vidId() { return this.$route.params.id }
  },

  methods : {
    t,

    async init() {
      const videos = await Fito().Plan.getVideos()
      const mediaLists = await Fito().Plan.getMediaLists()
      const list = mediaLists.find(it => it.name === 'live');
      if (list) {
        this.list = list;
        this.items = list.items.sort((a, b) => a.sort < b.sort ? -1 : 1);
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].src) this.items[i].video = videos.find(it => it.id === this.items[i].src * 1)
        }
      }
    },

    itemThumb(item) {
      if (item.thumbnail) return item.thumbnail;
      return Placeholder
    },
    itemLink(item) {
      return '/plan/live-stream/'+item.video.path+'';
    },
    itemActionIcon(item) {
      if (item.completed) return RefreshIcon;
      return PlayIcon
    }
  }

}
</script>
