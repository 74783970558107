<template>
    <div class="panel conscious-quiz-result">
    <div class="flex-1 centered-v">
      <div class="centered" v-if="result">
        <img :src="result.icon" />
        <h2>{{result.title}}</h2>
        <p>{{result.description}}</p>
      </div>
      <router-link class="btn" to="/plan/conscious">{{t("Back to Conscious Eating page")}}</router-link>
    </div>
  </div>
</template>

<script>
import t from '@/lib/Locale';

export default {
  name: "ConsciousQuizResult",
  props: {
    score: {type: Number},
    results: {type: Array},
  },
  data() {
    return {

    }
  },

  computed : {
    result() {
      let s = -1, r;
      for (let res of this.results) {
        if (res.min > s && this.score >= res.min) {
          s = res.min;
          r = res;
        }
      }
      return r
    }
  },

  methods : {
    t,
  }
}
</script>
