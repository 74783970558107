<template>
  <div>
    <Modal v-if="isWarPlan" class="pre" :closeButton="false">
      <p>{{t('war-thank-you')}}</p>
      <button class="btn" @click="$router.push('/plan/renew')">{{t("war-signup-button")}}</button>
    </Modal>
    <ExpiredForm v-if="expired" />
    <div v-if="suspended">
      <div class="boxes light">
        <Box :title="t('Account Suspended')" link="/contact"><p>{{suspended}}</p></Box>
      </div>
    </div>
    <div class="recipes-home" v-if="!expired && !suspended">
      <Scroller :items="topPopular" :pagination="true" type="top" link-prop="id" link-prefix="/plan/recipes/view/">
        <template v-slot:default="s">
          <div :style="{backgroundImage: `url(${s.item.images.length?s.item.images[0].image:'null'})`}" class="full-slide">
            <div class="content">
              <h3>{{s.item.name}}</h3>
              <Flexy><img :src="TimeWhiteIcon" /><span>{{t("Prep time: {0} minutes", s.item.prep_time)}}</span></Flexy>
            </div>
          </div>
        </template>
      </Scroller>
      <div class="container">
        <SearchInput :placeholder="t('Search for a recipe')" @change="onSearchInput" @focus="onSearchFocus" @filters="onSearchFilter" />
        <Flexy :between="true" class="section">
          <h3>{{t("Categories")}}</h3>
          <router-link to="/plan/recipes/categories">{{t("All Categories")}}</router-link>
        </Flexy>
        <div class="boxes scrolling small">
        <Scroller :items="activeCategories" :show-items="3" :infinite="true" link-prop="id" link-prefix="/plan/recipes/categories/">
          <template v-slot:default="s">
            <Box :thumb="s.item.image">
              <h3>{{s.item.name}}</h3>
              <p>{{t("{0} recipes", s.item.recipes)}}</p>
            </Box>
          </template>
        </Scroller>
        </div>

        <Flexy :between="true" class="section">
          <h3>{{t("Newest")}}</h3>
        </Flexy>
        <div class="boxes scrolling large">
          <Scroller :items="newest" :show-items="2" link-prop="id" link-prefix="/plan/recipes/view/">
            <template v-slot:default="s">
              <Box :thumb="getThumb(s.item)">
                <h3>{{s.item.name}}</h3>
                <label><img :src="TimeIcon" /><p>{{t("Prep time: {0} minutes", s.item.prep_time)}}</p></label>
              </Box>
            </template>
          </Scroller>
        </div>

        <Flexy :between="true" class="section">
          <h3>{{t("Popular Now")}}</h3>
        </Flexy>
        <div class="boxes scrolling large">
          <Scroller :items="bottomPopular" :show-items="2" link-prop="id" link-prefix="/plan/recipes/view/">
            <template v-slot:default="s">
              <Box :thumb="getThumb(s.item)">
                <h3>{{s.item.name}}</h3>
                <label><img :src="TimeIcon" /><p>{{t("Prep time: {0} minutes", s.item.prep_time)}}</p></label>
              </Box>
            </template>
          </Scroller>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Fito from '@/lib/fito';
import {applySavedFilters, populateCategories} from "@/lib/RecipeUtils";
import t from "@/lib/Locale";
import Scroller from "@/components/ui/widgets/Scroller";
import SearchInput from "@/components/ui/widgets/SearchInput";
import Flexy from "@/components/ui/widgets/Flexy";
import Box from "@/components/ui/widgets/Box";

import RecipePlaceholderImage from '@/assets/images/recipe-placeholder.png';
import TimeIcon from '@/assets/icons/time.svg';
import TimeWhiteIcon from '@/assets/icons/time-white.svg';
import ExpiredForm from "@/components/forms/ExpiredForm";
import Modal from "@/components/ui/widgets/Modal.vue";


const ITEMS_IN_POPULAR_SLIDER = 5;
const WAR_PLAN_ID = 9;

export default {

  name: "Recipes",
  components: {Modal, ExpiredForm, Box, Flexy, SearchInput, Scroller},
  data : function() {
    return {
      RecipePlaceholderImage, TimeWhiteIcon, TimeIcon,
      expired: false,
      suspended: false,
      isWarPlan: false,
      recipes: [],
      categories: [],
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')

    if (login.plan.id === WAR_PLAN_ID) {
      this.isWarPlan = true;
    }

    if (Fito().suspended) { this.suspended = Fito().suspended }

    try {

    this.recipes = await Fito().RunAction('get-recipes');
    this.categories = await Fito().RunAction('get-recipe-categories')
    this.tags = await Fito().RunAction('get-recipe-tags')
    this.recipes = applySavedFilters(this.recipes)
    } catch (e) {
      if (e.err_type === 'expired') {
        this.expired = true;
      }
    }

  },

  computed : {
    topPopular() {
      return this.recipes.slice(0, ITEMS_IN_POPULAR_SLIDER)
    },
    bottomPopular() {
      return this.recipes.slice(ITEMS_IN_POPULAR_SLIDER)
    },
    activeCategories() {
      if (!this.recipes.length || !this.categories.length) return []
      return populateCategories(this.categories, this.recipes, true);
    },
    newest() {
      return this.recipes.slice(Math.max(this.recipes.length - 5, 0))
    },
  },

  methods: {
    t,

    getThumb(recipe) {
      if (recipe.images && recipe.images.length > 0) return recipe.images[0].image
      return RecipePlaceholderImage;
    },

    onSearchFocus() {
      this.$router.push('/plan/recipes/search/')
    },
    onSearchInput(search) {
      if (search) this.$router.push('/plan/recipes/search/'+search)
    },
    onSearchFilter() {
      this.$router.push('/plan/recipes/filters')
    },

  },



}
</script>
