<template>
  <div class="panel-form centered">
    <h3>{{t('Restore password')}}</h3>
    <p class="info-box">{{t('Please enter the e-mail address you signed up with')}}</p>
    <InputWrapper v-model="identifier" type="text" :placeholder="t('For example: user@domain.com')" :validator="validator" />
    <label class="centered">
      <button class="btn" @click="trySubmit" :class="'submit'+(canSubmit?' active':' disabled')">{{t('Reset Password')}}</button>
    </label>
    <div v-if="error" class="error">
      <b>{{t('Error')}}</b>
      <div class="error-body">{{t(error)}}</div>
      <div>{{t('Please, Contact us and we will assist you as soon as we can.')}}</div>
    </div>
  </div>
</template>

<script>
import t from "@/lib/Locale";
import CheckIcon from "@/assets/icons/check.svg";
import InputWrapper from "@/components/ui/widgets/InputWrapper";

function cleanPhone(num) {
  return num.replace(/([ \-_])/g,'')
}

export default {
name: "ForgotForm",
  components: {InputWrapper},
  data : function() {
    return {
      CheckIcon,
      identifier: '',
    }
  },

  methods : {
    t,
    isValid : function(val, type) {
      switch (type) {
        case 'phone': return !isNaN(cleanPhone(val)*1) && cleanPhone(val).length >= 10 && cleanPhone(val).length <= 15;
        case 'email': return val.indexOf('@') > 0 && val.lastIndexOf(".") > val.lastIndexOf('@') && val.lastIndexOf(".") < val.length-2;
      }
      return false;
    },

    validator(val) {
        return this.isValid(val,'phone') || this.isValid(val, 'email')
    },

    trySubmit : function() {
      if (this.canSubmit && this.onSubmit) {
        this.onSubmit(this.identifier);
      }
    }
  },

  computed : {
    canSubmit : function() {
      return this.isValid(this.identifier, 'email') || this.isValid(this.identifier, 'phone')
    },
  },

  props : ['onSubmit', 'error'],
}
</script>
