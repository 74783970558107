<template>
  <div class="quiz-content">
    <div class="quiz-main">
      <h3>{{t(question)}}</h3>
      <div class="info" v-if="info">
        <p>{{t(info)}}</p>
      </div>
      <div :class="'quiz-squares'+(type ? ' '+type:'')">
        <div class="diagonal-wrap rad" v-for="(option, i) in answers" :key="option.label">
          <a class="square" @click="onAnswer(option.value)" :tabindex="i" href="javascript:void(0)" v-if="testOption(option)">
            <div class="square-icon" v-if="option.icon"><img :src="option.icon" /></div>
            <div class="square-title">{{t(option.label)}}</div>
          </a>
        </div>
      </div>
      <div v-if="type === 'slider'">
        <input type="range" :max="max" :value="sliderValue" @input="onSliderChange" @change="onSliderChange">
        <div class="slider-value">{{sliderValue}}</div>
        <div class="btn" @click="submitSlider">{{t("Next")}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import t from "@/lib/Locale";

export default {
  name: "QuizQuestion",
  props: ["question","answers","onAnswer", "info", "list", "type", "results", "goto","min","max"],
  data() {
    return {
      sliderValue: 1
    }
  },
  methods: {
    t,
    testOption(option) {
      if (!option.test) return true;
      return (option.test(this.results))
    },
    onSliderChange(e) {
      this.sliderValue = e.target.value
    },
    submitSlider() {
      this.onAnswer(this.sliderValue)
    }
  }
}
</script>

