<template>
  <div class="search-results boxes">
    <div v-for="(recipe, ii) in results" :key="ii">
      <router-link :to="'/plan/recipes/view/'+recipe.id">
      <div class="box">
        <Flexy>
          <img class="box-thumb" :src="recipeImage(recipe)" />
          <div>
            <h3>{{recipe.name}}</h3>
            <Flexy class="wraps">
              <img :src="TimeIcon" class="time-icon" />
              <p>{{t("Prep time: {0} minutes", recipe.prep_time)}}</p>
              <p v-for="(tag, ii) in allowedTags" :key="ii">{{tag.name}}</p>
            </Flexy>
          </div>
        </Flexy>
      </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import {searchRecipes} from "@/lib/RecipeUtils";
import Flexy from "@/components/ui/widgets/Flexy";
import TimeIcon from '@/assets/icons/time.svg';
import RecipePlaceholder from '@/assets/images/recipe-placeholder.png';
import t from "@/lib/Locale";


export default {
  name: "SearchResults",
  components: {Flexy},
  props: {
    search: { type: String },
    recipes : { type: Array },
    tags : { type: Array },
    categories : { type: Array },
  },
  data() {
    return {
      TimeIcon,

    }
  },

  computed : {
    results() {
      if (!this.search) return ''
      return searchRecipes(this.search, this.recipes, this.categories)
    },
    allowedTags() {
      if (!this.tags) return []
      let allowed = ['פרווה','בטיחותי בהיריון','קל','כשר','צמחוני','טבעוני']
      return this.tags.filter(it => allowed.indexOf(it.name) > -1)
    }
  },

  methods : {
    t,
    recipeImage(recipe) {
      return (recipe.images && recipe.images.length) ? recipe.images[0].image : RecipePlaceholder
    }
  }
}
</script>
