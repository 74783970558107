<template>
  <div class="panel-form">
    <app-form :on-submit="trySubmit">
      <InputWrapper v-model="email" type="email" :label="t('Email')" :placeholder="t('E-mail Address')" />
      <InputWrapper v-model="password" type="password" :label="t('Password')" :placeholder="t('Password (at least 8 letters)')" />
      <div class="between">
        <p><input type="checkbox" v-model="remember" /> {{t('Remember Me')}}</p>
        <a href="javascript:void(0)" @click="forgot" class="forgot-password">{{t('Forgot Password')}}</a>
      </div>

      <div v-if="error" class="error">
        <b>{{t('Error')}}</b>
        <div class="error-body">{{t(error)}}</div>
      </div>
      <div class="login-buttons">
        <button @click="trySubmit" tabindex="3" :class="'btn 3'+(canSubmit?' active':' disabled')">{{t('Sign me in')}}</button>
        <button tabindex="3" class="btn secondary" ref="googleLogin"><img :src="GoogleIcon" /> {{t('Sign in with Google')}}</button>
        <button tabindex="3" class="btn secondary" ref="facebookLogin" v-if="showFb" @click="facebookLogin"><img :src="FacebookIcon" /> {{t('Sign in with Facebook')}}</button>
      </div>
    </app-form>
  </div>
</template>

<script>
import AppForm from "@/components/forms/AppForm";
import CheckIcon from "@/assets/icons/check.svg";
import GoogleIcon from "@/assets/icons/external/google.svg";
import FacebookIcon from "@/assets/icons/external/facebook.svg";
import t from "@/lib/Locale";
import InputWrapper from "@/components/ui/widgets/InputWrapper";
import {getThirdPartyHash} from '@/lib/ThirdPartyAuth';

export default {
name: "LoginForm",
  components: {InputWrapper, AppForm },
  data : function() {
    return {
      CheckIcon,
      GoogleIcon,
      FacebookIcon,

      showFb: false,

      email: '',
      password: '',
      remember: false,
    }
  },

  mounted() {
      this.setupGoogle()
      if (this.$route.query &&this.$route.query.fb) {
        this.showFb = true
        setTimeout(() => this.setupFacebook(), 500)
      }
  },


  methods : {
    t,

    setupGoogle() {
      let auth2;
      const attachSignin = (element) => {
        auth2.attachClickHandler(element, {},
            (googleUser) => {
              let id = googleUser.getBasicProfile().getId(),
                  email = googleUser.getBasicProfile().getEmail();

              if (this.onSubmit) {
                console.log(id, getThirdPartyHash(id))
                this.onSubmit(email, getThirdPartyHash(id), id)
              }
            }, (error) => {
              console.error(JSON.stringify(error, undefined, 2));
            });
      }

      if (typeof window['gapi'] === 'undefined') return;
      window['gapi'].load('auth2', () => {
        auth2 = window['gapi']['auth2'].init({
          client_id: '817477447197-3dssj5vdmk0nhcggkuk8nm1r9gg19gpb.apps.googleusercontent.com',
          cookiepolicy: 'single_host_origin',
          // Request scopes in addition to 'profile' and 'email'
          //scope: 'additional_scope'
        });
        attachSignin(this.$refs.googleLogin);
      });


    },

    setupFacebook() {

        window.FB.init({
          appId      : '489981449233462',
          cookie     : true,                     // Enable cookies to allow the server to access the session.
          xfbml      : true,                     // Parse social plugins on this webpage.
          version    : 'v13.0'           // Use this Graph API version for this call.
        });


        window.FB.getLoginStatus(function(response) {   // Called after the JS SDK has been initialized.
          console.log(response);        // Returns the login status.
        });
    },

    isValid(val, type) {
      switch (type) {
        case 'email': return val.indexOf('@') > 0 && val.lastIndexOf(".") > val.lastIndexOf('@') && val.lastIndexOf(".") < val.length-2;
        case 'password': return val.length >= 8;
      }
      return false;
    },

    facebookLogin() {
      console.log("FB Login?")
      window.FB.login(function(response){
        console.log("FB Response", response)
      });
    },

    trySubmit() {
      if (this.canSubmit && this.onSubmit) {
        this.onSubmit(this.email, this.password);
      }
    },

    forgot : function() {
      if (this.onForgot) this.onForgot();
    }
  },

  computed : {
    canSubmit : function() {
      return this.isValid(this.email, 'email') && this.isValid(this.password,'password')
    },
  },

  props : ['onSubmit', 'onForgot', 'error'],
}
</script>
