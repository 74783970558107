import {doRequest} from "./Utils";
import ApiRoutes from './ApiRoutes';

export default ApiRoutes

export function ApiCall(hostname, endpoint, body = null, query = null, param = null) {

    // replace body for query and then to params to allow using 1st param for queries or params
    if (!endpoint.body && (endpoint.query || endpoint.param) && body && !query) { query = body; body = null; }
    if (!endpoint.query && endpoint.param && query && !param) { param = query; }

    let req = {
        body : {},
        method: endpoint.method,
        param: endpoint.param,
        url : '',
    };

    if (endpoint.body) {
        if (endpoint.body instanceof Array) {
            for (let i of endpoint.body) if (typeof body[i] !== "undefined") req.body[i] = body[i];
        }
        else {
            req.body = body;
        }
    }

    let url = new URL(hostname + endpoint.path);
    if (endpoint.param) url += (url[url.length-1]==='/'?'':'/') + param;

    if (endpoint.query) {
        for (let i of endpoint.query) if (typeof query[i] !== "undefined") url.searchParams.append(i, query[i]);
    }

    req.url = url.toString();

    if (endpoint.apiKey) req.apiKey = true

    return req;
}

export async function ApiRequest(call, apiKey, token) {
    return await doRequest(call.url, call.method, call.body, call.apiKey ? {"Brand-Api-Key" : apiKey, "Subscriber-Token": token}:null);
}
