<template>
  <div class="item-view">
    <div class="item-jumbo">
      <div class="jumbo-overlay">
        <router-link class="btn" v-if="list" :to="'/plan/yoga/'+list.name"><img :src="ArrowRightIcon"></router-link>
        <a class="btn" @click="toggleFavorite"><img :src="favorite?HeartFullIcon:HeartIcon"></a>
      </div>
      <Vimeo :path="path" />
    </div>
    <div class="item-details">
      <h2>{{title}}</h2>
      <p class="primary">{{content}}</p>
      <button class="btn" @click="togglePracticeCompletion"><img :src="CheckCircleIcon" v-if="completed" /> {{t(completed?"I finished the practice":'I finished the practice')}}</button>
    </div>
  </div>
</template>

<script>

import t from '@/lib/Locale';
import Fito from "@/lib/fito";
import CheckCircleIcon from '@/assets/icons/check-circle.svg';
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';
import HeartIcon from '@/assets/icons/heart.svg';
import HeartFullIcon from '@/assets/icons/heart-full.svg';
import Vimeo from "@/components/ui/widgets/Vimeo";

export default {
  name: "YogaPractice",
  components: {Vimeo},
  props: ['meta'],
  data() {
    return {
      CheckCircleIcon, ArrowRightIcon, HeartIcon, HeartFullIcon,

      list : null,
      id: null,
      title: "",
      content: "",
      path: "",
      completed: false,

      favorite : false
    }
  },

  async mounted() {

    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')


    const meta = login.meta;
    const videos = await Fito().Plan.getVideos()
    const mediaLists = await Fito().Plan.getMediaLists()
    const list = mediaLists.find(it => it.name === this.$route.params.list);
    if (list) {
      this.list = list;
      const item = list.items.find(it => it.id === this.$route.params.id*1)
      item.video = videos.find(it => it.id === item.src*1)
      if (item) {
        this.id = item.id;
        this.title = item.title;
        this.content = item.content;
        this.path = item.video.path;
      }
    }

    if (meta['practice-favorites'] && meta['practice-favorites'] instanceof Array) {
      const found = meta['practice-favorites'].find(it => it.practice_id*1 === this.id*1 && it.list_id*1 === this.list.id*1)
      if (found) this.favorite = true
    }

    if (this.meta && this.meta['completed-practices']) {
      const {list, id} = this.$route.params;
      if (this.meta['completed-practices'].find(p => p.list === list && p.practice_id === id)) this.completed = true;
      else if (this.meta['completed_yoga'] && this.meta['completed_yoga'] && this.meta['completed_yoga'][list] && this.meta['completed_yoga'][list][id]) this.completed = true
    }

    await Fito().Plan.Log('watch-practice', this.id+': '+this.title)

  },

  methods : {
    t,

    async togglePracticeCompletion() {
      const {list, id} = this.$route.params;
      let completed = this.meta['completed-practices'] && this.meta['completed-practices'] instanceof Array ? this.meta['completed-practices'] : [], isCompleted = false;
      if (completed.find(p => p.practice_id*1 === id*1)) { // already completed = cancel!
        await Fito().RunAction('remove-completed-practice', {list, practice_id: id})
        completed = completed.filter(it => it.practice_id !== id || it.list !== list)
      }
      else { // not completed = complete!
        await Fito().RunAction('add-completed-practice', {list, practice_id: id})
        completed.push({practice_id: id, list})
        isCompleted = true
      }

      this.$emit('meta', 'completed-practices', completed)
      this.completed = isCompleted;
    },

    updateFavorites() {

    },

    async toggleFavorite() {
      this.favorite = !this.favorite

      if (this.favorite) {
        await Fito().RunAction('add-practice-favorite', {practice_id: this.id, list_id: this.list.id})
      }
      else {
        await Fito().RunAction('remove-practice-favorite', {practice_id: this.id, list_id: this.list.id})
      }
    }
  }

}
</script>
