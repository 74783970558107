<template>
  <div class="conscious-quiz">
    <router-link to="/plan/conscious" class="back-link"><img :src="ArrowRightIcon" /></router-link>
    <div class="quiz-intro">
        <div class="quiz-intro-logo">
          <img :src="ConsciousQuizIcon" />
        </div>
        <div class="quiz-intro-text">
          {{t("conscious-quiz-intro-text")}}
        </div>
        <button class="btn" @click="onStart">{{t("Let's start the quiz")}}</button>
      </div>
  </div>
</template>

<script>
import ConsciousQuizIcon from '@/assets/icons/conscious/conscious-quiz.svg';
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';
import t from '@/lib/Locale';
import Fito from "@/lib/fito";
export default {
  name: "ConsciousQuizIntro",
  props: ['meta'],
  data() {
    return {
      ArrowRightIcon,
      ConsciousQuizIcon,
    }
  },
  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')

  },
  methods : {
    t,
    onStart() {
      this.$router.push('/plan/conscious/quiz/go')
    }
  },
}
</script>
