<template>
  <div class="daily-menu">
    <router-link to="/plan/menus/" class="back-link">
      <Flexy><img :src="ArrowRightIcon" /> <p>{{t("Your Week")}}</p></Flexy>
    </router-link>
    <Flexy :between="true" class="days">
      <div v-for="(day, ii) of days" :key="ii" :class="'day' + (today(ii)?' active':'')+ (selected(ii)?' selected':'')+ (dayComplete(ii)?' complete':'')" @click="selectDate(ii)">
        <small>{{day}}</small>
        <b>{{date(ii)}}</b>
      </div>
    </Flexy>
    <div class="centered">
      {{prettyDate}}
    </div>
    <div class="meals boxes">
      <Box v-for="(meal, ii) of meals" :key="ii" :class="'meal' + (mealExists(selectedDateIndex, ii)? '':' default')" :title="meal" :thumb="mealThumb(selectedDateIndex, ii)">
        <small>{{mealName(selectedDateIndex, ii)}}</small>
        <template v-slot:actions>
          <div v-if="mealExists(selectedDateIndex, ii)">
            <div class="meal-menu-btn" @click="toggleMealMenu(ii)"><img :src="dotMenuIcon" /></div>
            <div class="meal-menu" v-if="openMenu === ii">
              <div class="meal-menu-inner">
                <Flexy @click.native="chooseMeal(ii)">
                  <img :src="editIcon" />
                  <p>{{t("Edit")}}</p>
                </Flexy>
                <Flexy @click.native="clearMeal(ii)">
                  <img :src="trashIcon" />
                  <p>{{t("Delete Meal")}}</p>
                </Flexy>
              </div>
            </div>
          </div>
          <div v-if="!mealExists(selectedDateIndex, ii)">
            <span class="btn small" @click="chooseMeal(ii)">{{t("Choose Meal")}}</span>
          </div>
        </template>
      </Box>
    </div>

  </div>
</template>

<script>

import ArrowRightIcon from '@/assets/icons/arrow-right.svg';
import mealDefaultIcon from '@/assets/icons/nutrition/meal-default.svg';
import dotMenuIcon from '@/assets/icons/nutrition/dotmenu.svg';
import editIcon from '@/assets/icons/nutrition/trash.svg';
import trashIcon from '@/assets/icons/nutrition/edit.svg';
import t from "@/lib/Locale";
import Flexy from "@/components/ui/widgets/Flexy";
import Fito from "@/lib/fito";
import Box from "@/components/ui/widgets/Box";

const days = [t("Su"),t("Mo"),t("Tu"),t("We"),t("Th"),t("Fr"),t("Sa")]
const months = [t("Jan"),t("Feb"),t("Mar"),t("Apr"),t("May"),t("Jun"),t("Jul"),t("Aug"),t("Sep"),t("Oct"),t("Nov"),t("Dec")]
const meals = [t("Breakfast"), t("Lunch"),t("Snack Meal"),t("Dinner")]

export default {
  name: "MenusDailyMenu",
  components: {Box, Flexy},
  data() {
    return {
      days, meals, months,
      ArrowRightIcon, dotMenuIcon, editIcon, trashIcon,
      recipeTags: null,
      startDate : new Date(),
      selectedDate: new Date(),
      openMenu: null,
      selectedDateIndex : new Date().getDay(),
      daysIn : null,
      menu: []
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')



    const date = localStorage.getItem('meal-plan-date');
    if (!date) localStorage.setItem('meal-plan-date', this.startDate.toJSON())
    else {
      this.selectedDate = new Date(date);
      this.selectedDateIndex = new Date(date).getDay()
    }

    try {
      let [m, menuPlanAge] = await Fito().RunAction('get-menus-plan', true);
      if (m && m.id) m = m.id

      this.daysIn = 1+Math.floor(menuPlanAge / 60 / 60 / 24 );
      this.plan = m;
      if (!this.plan) {
        await this.$router.push('/plan/menus/choose-plan')
      }

      this.recipes = await Fito().RunAction('get-recipes')
      this.recipeTags = await Fito().RunAction('get-recipe-tags')
      this.menu = await Fito().RunAction('get-menu');
      if (!this.menu) this.menu = []
    } catch (e) {
      if (e.err_type === 'expired') {
        await this.$router.push('/plan/menus/')
      }
    }
  },

  computed : {
    sunday() {
      const d = new Date()
      d.setDate(d.getDate() - d.getDay());
      return d
    },
    prettyDate() {
      const d = this.days[this.selectedDate.getDay()]
      return t("{0}, {1}.{2}.{3}", d, this.selectedDate.getDate(), this.months[this.selectedDate.getMonth()], this.selectedDate.getFullYear())
    }
  },

  methods : {
    t,

    toggleMealMenu(ii) {
      if (this.openMenu === ii) this.openMenu = null;
      else this.openMenu = ii;
    },
    date(index) {
      const d = new Date(this.sunday.toJSON());
      d.setDate(d.getDate()+index)
      return d.getDate();
    },

    selectDate(index) {
      if (this.selectedDateIndex === index) return;

      const d = new Date(this.sunday.toJSON());
      if (index) d.setDate(d.getDate()+index)
      this.selectedDate = d;
      this.openMenu = null;
      this.selectedDateIndex = index;
      localStorage.setItem('meal-plan-date', d.toJSON())
      localStorage.setItem('meal-plan-date-index', index)
    },

    today(index) {
      const d = new Date(this.sunday.toJSON());
      d.setDate(d.getDate()+index)
      return (Math.abs(new Date().getTime() - d.getTime()) < 1000000)
    },

    selected(index) {
      const d = new Date(this.sunday.toJSON());
      d.setDate(d.getDate()+index)
      return (Math.abs(d.getTime() - this.selectedDate.getTime()) < 1000000)
    },

    myMeals(index) {
      let d = new Date(this.sunday.toJSON());
      d.setDate(d.getDate()+index)
      d = d.toJSON().substr(0, 10)

      let meals = [null, null, null, null]

      for (let meal of this.menu) {
        if (meal instanceof Array) {
          if (meal[0] === d) meals[meal[1]*1] = this.recipes.find(it => it.id*1 === meal[2]*1)
        }
        else {
          if(meal.date.substr(0, 10) === d) {
            meals[meal.meal_index*1] = this.recipes.find(it => it.id*1 === meal.recipe_id*1)
          }
        }
      }

      return meals;

    },

    mealExists(dayIndex, mealIndex) {
      return !!this.myMeals(dayIndex)[mealIndex]
    },

    dayComplete(dayIndex) {
      const meals = this.myMeals(dayIndex)
      for (let m of meals) if (!m) return false
      return true
    },

    mealName(dayIndex, mealIndex) {
      if (this.myMeals(dayIndex)[mealIndex]) return this.myMeals(dayIndex)[mealIndex].name
      return t("A meal was not selected")
    },

    mealThumb(dayIndex, mealIndex) {

      if (this.myMeals(dayIndex)[mealIndex] && this.myMeals(dayIndex)[mealIndex].images.length) return this.myMeals(dayIndex)[mealIndex].images[0].image

      return mealDefaultIcon;
    },

    chooseMeal(index) {
      let mealTag = '';
      switch (index) {
        case 0: mealTag = 'ארוחת בוקר'; break
        case 1: mealTag = 'ארוחת צהריים'; break
        case 2: mealTag = 'ארוחת ביניים'; break
        case 3: mealTag = 'ארוחת ערב'; break
      }
      if (this.recipeTags) for (let tag of this.recipeTags) {
        if (tag.name === mealTag) {
          localStorage.setItem('meal-plan-meal', meals[index])
          localStorage.setItem('meal-plan-meal-index', index)
          localStorage.setItem('meal-plan-tag', tag.id)
          this.$router.push('/plan/menus/select-meal')
          break
        }
      }
    },

    async clearMeal(meal_index) {
      await Fito().RunAction('clear-menu-meal', {date: this.selectedDate.toJSON().substr(0, 10), meal_index})
      this.menu = this.menu.filter(it => {
        return it instanceof Array ?
            (it[0] !== this.selectedDate.toJSON().substr(0, 10) || it[1]*1 !== meal_index*1) :
            (it.date !== this.selectedDate.toJSON().substr(0, 10) || it.meal_index*1 !== meal_index*1)
      })
      this.openMenu = null;
    }
  }
}
</script>
