<template>
  <div class="container-m">
    <div class="centered">
      <h2>{{t("Sign Up Complete")}}</h2>
      <p>{{t("We are forwarding you to your plan...")}}</p>
    </div>
  </div>
</template>

<script>
import t from '@/lib/Locale';
import Fito from '@/lib/fito'

export default {
  name: "Signup",
  async mounted() {
    await this.completeSignup()
  },

  methods : {
    t,
    async completeSignup() {
      const signupToken = localStorage.getItem('SignupToken');
      const {res, token} = await Fito().Signup.CompleteSignup(signupToken)
      if (res === 'ok') {
        console.log("New token:", token)
        localStorage.setItem("login-token", token);
        localStorage.removeItem('SignupToken')

        setTimeout(() => {
          window.location.href = '/plan/yoga'
        }, 5000)
      }
    }
  }
}
</script>
