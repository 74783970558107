<template>
  <div class="item-view">
    <div class="item-jumbo">
      <div class="jumbo-overlay">
        <router-link class="btn" v-if="list" :to="'/plan/conscious/'+listName"><img :src="ArrowRightIcon"></router-link>
        <a class="btn"><img :src="HeartIcon"></a>
      </div>
      <Vimeo :path="path" />
    </div>
    <div class="item-details">
      <h2>{{title}}</h2>
      <p class="primary">{{content}}</p>
    </div>
  </div>
</template>

<script>

import t from '@/lib/Locale';
import Fito from "@/lib/fito";
import CheckCircleIcon from '@/assets/icons/check-circle.svg';
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';
import HeartIcon from '@/assets/icons/heart.svg';
import Vimeo from "@/components/ui/widgets/Vimeo";

const listName = "conscious-video";

export default {
  name: "ConsciousPractice",
  components: {Vimeo},
  props: ['meta'],
  data() {
    return {
      CheckCircleIcon,
      ArrowRightIcon,
      HeartIcon,

      listName,

      list : null,
      title: "",
      content: "",
      path: ""
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')


    const videos = await Fito().Plan.getVideos()
    const mediaLists = await Fito().Plan.getMediaLists()
    const list = mediaLists.find(it => it.name === listName);
    if (list) {
      this.list = list;
      const item = list.items.find(it => it.id === this.$route.params.id*1)
      item.video = videos.find(it => it.id === item.src*1)
      if (item) {
        this.title = item.title;
        this.content = item.content;
        this.path = item.video.path;
      }
    }
  },

  methods : {
    t,

  }

}
</script>
