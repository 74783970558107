let missing = [], debugTimeout;
import locale from './locales/he.json';

function debugMissing() {
    clearTimeout(debugTimeout);
    debugTimeout = setTimeout(() => {
        let d = {}
        for (let o in missing) d[missing[o]] = ""

        console.log("[Locale]", "Missing translations:',", JSON.stringify(d))
    }, 500);
}

if (!String.prototype.format) {
    String.prototype.format = function(args = []) {
        return this.replace(/{(\d+)}/g, (match, number) => typeof args[number] != 'undefined' ? args[number] : match);
    };
}

function translate(string, ...args) {
    let res = string.toString(), found = false;

    if (locale) {
        if (locale[string]) {
            res = locale[string];
            found = true;
        }
    }
    if (!found) {
        if (missing.indexOf(string) < 0) {
            missing.push(string);

            debugMissing();
        }
    }
    if (args.length > 0) { res = res.format(args); }
    return res;
}

export default translate;
