<template>
  <div class="panel raw">
    <AppForm :on-submit="trySubmit">
      <InputWrapper :label="t('Name')" type="name" v-model="name" :placeholder="t('Name')" />
      <InputWrapper :label="t('E-mail *')" type="email" v-model="email" :placeholder="t('E-mail Address')" />
      <InputWrapper :label="t('Phone Number')" type="phone" v-model="phone" :placeholder="t('Phone Number')" />
      <InputWrapper :label="t('Subject')" type="name" v-model="subject" :placeholder="t('Subject')" />
      <InputWrapper :label="t('Message')" type="textarea" v-model="message" :placeholder="t('Message')" />

      <label class="border-top">
          <button @click="trySubmit" :class="'btn submit'+(canSubmit?'':' disabled')">{{t('Send')}}</button>
      </label>
      <div v-if="error" class="error">
        <b>{{t('Error')}}</b>
        <div class="error-body">{{t(error)}}</div>
      </div>
    </AppForm>
  </div>
</template>

<script>

import CheckIcon from "@/assets/icons/check.svg";
import t from "@/lib/Locale";
import AppForm from "@/components/forms/AppForm";
import InputWrapper from "@/components/ui/widgets/InputWrapper";
function cleanPhone(num) {
  return num.replace(/([ \-_])/g,'')
}

export default {
  name: "ContactForm",
  components: {InputWrapper, AppForm},
  props : ['onSubmit', 'error', 'subscriberName','subscriberEmail','subscriberPhone'],

  data : function() {
    return {
      CheckIcon,

      email: '',
      phone: '',
      name: '',
      subject: '',
      message : '',
    }
  },


  mounted() {
    if (this.subscriberEmail) this.email = this.subscriberEmail
    if (this.subscriberName) this.name = this.subscriberName
    if (this.subscriberPhone) this.phone = this.subscriberPhone
  },

  methods : {
    t,
    isValid : function(val, type) {
      switch (type) {
        case 'email': return val.indexOf('@') > 0 && val.lastIndexOf(".") > val.lastIndexOf('@') && val.lastIndexOf(".") < val.length-2;
        case 'phone': return !isNaN(cleanPhone(val)*1) && cleanPhone(val).length >= 10 && cleanPhone(val).length <= 15;
        case 'name': return val.length > 1;
        case 'subject': return val.length > 1;
      }
      return false;
    },

    trySubmit : function() {
      if (this.canSubmit && this.onSubmit) {
        this.onSubmit(this.email, cleanPhone(this.phone), this.name, this.subject, this.message);
      }
    }
  },

  computed : {
    canSubmit : function() {
      return this.isValid(this.email, 'email') && this.isValid(this.phone, 'phone') &&
          this.isValid(this.name, 'name') && this.isValid(this.subject,'subject')
          && this.isValid(this.message,'subject')
    },
  },

  watch : {
    subscriberEmail() { this.email = this.subscriberEmail },
    subscriberName() { this.name = this.subscriberName },
    subscriberPhone() { this.phone = this.subscriberPhone },
  }
}
</script>
