<template>
  <form @submit="submit">
    <slot></slot>
  </form>
</template>

<script>
export default {
name: "AppForm",
  methods : {
    submit(e) {
      this.onSubmit();
      e.preventDefault();
    }
  },

  props : ["onSubmit"],
}
</script>
