<template>
  <div class="results-view container-m">
    <div class="centered results-intro">
      <h1>{{t("Welcome to Layoga")}}</h1>
      <p>{{t("Your home to Yoga and healthy lifestyle")}}</p>
      <router-link to="/plan/signup" class="btn join">{{t("Join Now")}} <img :src="ArrowLeftIcon" class="spaced" /></router-link>
    </div>

    <div class="video-placeholder">
      <Vimeo path="681495625" />
    </div>

    <div class="reasons">
      <div class="panel soft">
        <div class="floating-logo">
          <img :src="MeditationIcon" />
        </div>
        <div class="centered">
          <h3>{{t("reason-1-title")}}</h3>
          <p>{{t("reason-1-text")}}</p>
        </div>
      </div>

      <div class="panel soft">
        <div class="floating-logo">
          <img :src="ScheduleIcon" />
        </div>
        <div class="centered">
          <h3>{{t("reason-2-title")}}</h3>
          <p>{{t("reason-2-text")}}</p>
        </div>
      </div>

      <div class="panel soft">
        <div class="floating-logo">
          <img :src="HealthIcon" />
        </div>
        <div class="centered">
          <h3>{{t("reason-3-title")}}</h3>
          <p>{{t("reason-3-text")}}</p>
        </div>
      </div>
    </div>

    <router-link to="/plan/signup" class="btn join">{{t("Join Now")}} <img :src="ArrowLeftIcon" class="spaced" /></router-link>

    <div class="reasons included">
      <div class="header centered">
        <h3>{{t("So, what's included in the plan?")}}</h3>
        <p>{{t("included-text")}}</p>
      </div>

      <div class="panel soft">
        <div class="floating-logo">
          <b>1</b>
        </div>
        <div class="centered">
          <h3>{{t("included-1-title")}}</h3>
          <p>{{t("included-1-text")}}</p>
        </div>
      </div>

      <div class="panel soft">
        <div class="floating-logo">
          <b>2</b>
        </div>
        <div class="centered">
          <h3>{{t("included-2-title")}}</h3>
          <p>{{t("included-2-text")}}</p>
        </div>
      </div>

      <div class="panel soft">
        <div class="floating-logo">
          <b>3</b>
        </div>
        <div class="centered">
          <h3>{{t("included-3-title")}}</h3>
          <p>{{t("included-3-text")}}</p>
        </div>
      </div>

      <div class="panel soft">
        <div class="floating-logo">
          <b>4</b>
        </div>
        <div class="centered">
          <h3>{{t("included-4-title")}}</h3>
          <p>{{t("included-4-text")}}</p>
        </div>
      </div>

    </div>

    <router-link to="/plan/signup" class="btn join">{{t("Join Now")}} <img :src="ArrowLeftIcon" class="spaced" /></router-link>

    <div class="about-plan">
      <div class="centered">
        <img class="team-image rounded" :src="Team1Image" />
        <h3>{{t("Hi, I'm Miran Bouzaglo")}}</h3>
        <p>{{t("miran-intro-1-text")}}</p>
        <a href="https://instagram.com/miranbuzaglo" target="_blank" class="instagram-link"><img :src="InstagramIcon" /> <p>@miranbuzaglo</p></a>

        <h3>{{t("So what do I want exactly?")}}</h3>
        <p>{{t("miran-intro-2-text")}}</p>
      </div>

      <div class="video-placeholder">
        <Vimeo path="681494149" />
      </div>

      <router-link to="/plan/signup" class="btn join raw">{{t("Join me now")}} <img :src="ArrowLeftIcon" class="spaced" /></router-link>

    </div>

    <div class="centered">
      <h3>{{t("Meet Layoga's professional team")}}</h3>
      <div class="team-member">
        <img class="team-image" :src="Team2Image" />
        <h3>{{t("team-member-1-name")}}</h3>
        <p>{{t("team-member-1-text")}}</p>
        <a href="https://instagram.com/kananda_yoga" target="_blank" class="instagram-link"><img :src="InstagramIcon" /> <p>@kananda_yoga</p></a>
      </div>
      <div class="team-member">
        <img class="team-image" :src="Team3Image" />
        <h3>{{t("team-member-2-name")}}</h3>
        <p>{{t("team-member-2-text")}}</p>
        <a href="https://instagram.com/ofrilaski" target="_blank" class="instagram-link"><img :src="InstagramIcon" /> <p>@ofrilaski</p></a>
      </div>
      <div class="team-member">
        <img class="team-image" :src="Team4Image" />
        <h3>{{t("team-member-3-name")}}</h3>
        <p>{{t("team-member-3-text")}}</p>
        <a href="https://instagram.com/maya_hatzor" target="_blank" class="instagram-link"><img :src="InstagramIcon" /> <p>@maya_hatzor</p></a>
      </div>
    </div>

    <router-link to="/plan/signup" class="btn join">{{t("Join Now")}} <img :src="ArrowLeftIcon" class="spaced" /></router-link>


    <div class="qa">
      <h3 class="centered">{{t('Questions and answers')}}</h3>
      <Qa :items="QAData" />
    </div>

    <PoweredBy />

  </div>

</template>

<script>
import t from '@/lib/Locale';
import ArrowLeftIcon from "@/assets/icons/results/arrow-left.svg";
import MeditationIcon from "@/assets/icons/results/meditation.svg";
import ScheduleIcon from "@/assets/icons/results/schedule.svg";
import HealthIcon from "@/assets/icons/results/health.svg";
import InstagramIcon from "@/assets/icons/results/instagram.svg";

import Team1Image from "@/assets/images/team/miran.jpg";
import Team2Image from "@/assets/images/team/2.png";
import Team3Image from "@/assets/images/team/3.png";
import Team4Image from "@/assets/images/team/4.png";
import Qa from "@/components/ui/widgets/Qa";
import QAData from "@/lib/docs/QAData";
import Vimeo from "@/components/ui/widgets/Vimeo";
import PoweredBy from "@/components/ui/widgets/PoweredBy.vue";



export default {
  name: "Results",
  components: {PoweredBy, Vimeo, Qa},
  props: ['stats','invoice'],
  data() {
    return {
      QAData,
      ArrowLeftIcon, MeditationIcon, ScheduleIcon, HealthIcon, InstagramIcon,
      Team1Image, Team2Image, Team3Image, Team4Image,

      savedStats : null,
      plans: []
    }
  },


  mounted() {
    if (localStorage.getItem('login-token')) {
      window.location.href = '/plan/yoga';
      return false;
    }

    if (!localStorage.getItem('QuizAnswers')) {
      window.location.href = '/quiz';
      return false;
    }

  },

  methods: {
    t,
    scrollToPurchase() {
      window.scrollTo({
        top: document.querySelector('#purchase-plan').offsetTop,
        behavior: 'smooth'
      });
    },

  },
  computed : {
  }
}
</script>
