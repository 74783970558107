<template>
  <div>
    <router-link to="/plan/menus/" class="back-link">
      <Flexy><img :src="ArrowRightIcon" /> <p>{{t("Rules and instructions")}}</p></Flexy>
    </router-link>
    <div class="container menus-instructions">
      <h3>{{t("rules-title")}}</h3>
      <ol>
        <li v-for="(rule, ii) of this.planRules" :key="ii">{{rule}}</li>
      </ol>
    </div>
  </div>
</template>

<script>

import ArrowRightIcon from '@/assets/icons/arrow-right.svg';
import t from "@/lib/Locale";
import Flexy from "@/components/ui/widgets/Flexy";
import Fito from "@/lib/fito";

let rules = [];
for (let i = 1; i <= 10; i++) rules.push(t("menu-rule-"+i))


export default {
  name: "MenusInstructions",
  components: {Flexy},
  data() {
    return {
      rules,
      planRules: [],
      ArrowRightIcon,
    }
  },
  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')


    let planId = await Fito().RunAction('get-menus-plan');
    if (planId.id) planId = planId.id

    const plans = await Fito().RunAction('get-menus-plans');
    const myPlan = plans.find(p => p.id*1 === planId*1)
    if (myPlan.rules) {
      this.planRules = myPlan.rules.split(/\n/g)
    }
  },
  methods : {
    t,
  }
}
</script>
