<template>
  <div class="view-contact container">
    <div class="centered">
      <h3>{{t('The site is down due to unusual traffic. Please try again in a few minutes')}}</h3>
    </div>
  </div>
</template>

<script>
import t from "@/lib/Locale"

export default {
  name: "SiteBusy",
  methods : {
    t,
  }
}
</script>
