<template>
  <div class="container">
    <router-link to="/plan/recipes" class="back-link">
      <Flexy><img :src="ArrowRightIcon" /> <p>{{t("Categories")}}</p></Flexy>
    </router-link>
    <div class="boxes halves">
        <div class="box" v-for="(category, ii) in activeCategories" :key="ii">
          <router-link :to="'/plan/recipes/categories/'+category.id">
            <div class="box-thumb" :style="{backgroundImage: `url(${category.image})`}">
            </div>
            <h3>{{category.name}}</h3>
            <p>{{t("{0} recipes", category.recipes)}}</p>
          </router-link>
        </div>
      </div>
  </div>
</template>

<script>

import Fito from '@/lib/fito';
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';
import t from "@/lib/Locale";
import Flexy from "@/components/ui/widgets/Flexy";
import {applySavedFilters, populateCategories} from "@/lib/RecipeUtils";

export default {

  name: "Recipes",
  components: { Flexy},
  data : function() {
    return {
      ArrowRightIcon,

      recipes : [],
      categories: [],
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')



    this.recipes = await Fito().RunAction('get-recipes');
    this.categories = await Fito().RunAction('get-recipe-categories')

    this.recipes = applySavedFilters(this.recipes)

  },

  methods: {
    t,

  },

  computed : {
    activeCategories() {
      if (!this.recipes.length || !this.categories.length) return []
      return populateCategories(this.categories, this.recipes, true)
    },
  },


}
</script>
