<template>
  <div class="conscious-quiz">
    <router-link to="/plan/conscious" class="back-link" v-if="score === null"><Flexy><img :src="ArrowRightIcon" /> <span>{{t("Conscious Eating")}}</span></Flexy></router-link>

      <ConsciousQuizQuestions :questions="quizData.questions" :on-results="onResults" v-if="!score" />
      <ConsciousQuizResult :score="score" :results="quizData.results" v-if="score" />
  </div>
</template>

<script>

import ArrowRightIcon from '@/assets/icons/arrow-right.svg';
import t from '@/lib/Locale';
import ConsciousQuizQuestions from "@/components/conscious/ConsciousQuizQuestions";
import Flexy from "@/components/ui/widgets/Flexy";
import quizData from '@/lib/docs/ConsciousQuizData';
import ConsciousQuizResult from "@/components/conscious/ConsciousQuizResult";
import Fito from "@/lib/fito";

export default {
  name: "ConsciousQuiz",
  components: {ConsciousQuizResult, Flexy, ConsciousQuizQuestions},
  props: ['meta'],
  data() {
    return {
      score: null,
      ArrowRightIcon,
      quizData
    }
  },
  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')

  },
  methods : {
    t,
    onResults(score) {
      this.score = score
    }
  },
}
</script>
