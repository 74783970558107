<template>
  <div class="signup-form">
    <slot></slot>
    <div class="login-buttons">
      <button tabindex="3" class="btn secondary" ref="googleLogin"><img :src="GoogleIcon" /> {{t('Sign in with Google')}}</button>
    </div>
    <div class="panel raw">

    <app-form :on-submit="trySubmit" class="panel-form">
      <InputWrapper v-model="first_name" :label="t('First Name')" type="name" :placeholder="t('First Name')"/>
      <InputWrapper v-model="last_name" :label="t('Last Name')" type="name" :placeholder="t('Last Name')" />
      <InputWrapper v-model="email" :label="t('Email')" type="email" :placeholder="t('E-mail Address')" />
      <InputWrapper v-model="password" :label="t('Password')" type="password" :placeholder="t('Your password must contain at least 8 letters')" />

      <small>{{t("By signing up you confirm you agree to our")}} <a href="/terms" target="_blank">{{t("Terms of use")}}</a></small>
      <div class="login-buttons">
        <button v-on:click="trySubmit" tabindex="3" :class="'btn 3'+(canSubmit?' active':' disabled')">{{t('Sign me up')}}</button>
      </div>

      <div v-if="error" class="error">
        <b>{{t('Error')}}</b>
        <div class="error-body">{{t(error)}}</div>
      </div>
    </app-form>
    </div>
  </div>
</template>

<script>
import AppForm from "@/components/forms/AppForm";
import CheckIcon from "@/assets/icons/check.svg";
import GoogleIcon from "@/assets/icons/external/google.svg";
import FacebookIcon from "@/assets/icons/external/facebook.svg";
import t from "@/lib/Locale";
import InputWrapper from "@/components/ui/widgets/InputWrapper";
import {getThirdPartyHash} from "@/lib/ThirdPartyAuth";
export default {
name: "SignupForm",
  components: {InputWrapper, AppForm },
  props : {
    details : {type: Object},
    onSubmit : {type: Function},
    onForgot : {type: Function},
    error : {type: String},
  },

  data : function() {
    return {
      CheckIcon,
      GoogleIcon,
      FacebookIcon,

      first_name: '',
      last_name: '',
      email: '',
      password: '',
      remember: false,
    }
  },

  mounted() {
      this.reloadDetails();
      this.googleInit();
  },

  methods : {
    t,

    googleInit() {
      if (typeof window['gapi'] === 'undefined') return;
      let auth2;
      const attachSignin = (element) => {
        auth2.attachClickHandler(element, {},
            (googleUser) => {
              let id = googleUser.getBasicProfile().getId(),
                  first_name = googleUser.getBasicProfile().getGivenName(),
                  last_name = googleUser.getBasicProfile().getFamilyName(),
                  email = googleUser.getBasicProfile().getEmail();

              if (this.onSubmit) {
                this.onSubmit(first_name, last_name, email, getThirdPartyHash(id))
              }
            }, (error) => {
              console.error(JSON.stringify(error, undefined, 2));
            });
      }
      window['gapi'].load('auth2', () => {
        auth2 = window['gapi']['auth2'].init({
          client_id: '817477447197-3dssj5vdmk0nhcggkuk8nm1r9gg19gpb.apps.googleusercontent.com',
          cookiepolicy: 'single_host_origin',
          // Request scopes in addition to 'profile' and 'email'
          //scope: 'additional_scope'
        });
        attachSignin(this.$refs.googleLogin);
      });
    },

    isValid : function(val, type) {
      switch (type) {
        case 'email': return val.indexOf('@') > 0 && val.lastIndexOf(".") > val.lastIndexOf('@') && val.lastIndexOf(".") < val.length-2;
        case 'password': return val.length >= 8;
        case 'name': return val.length >= 2;
      }
      return false;
    },

    trySubmit : function() {
      if (this.onSubmit) {
        this.onSubmit(this.first_name, this.last_name, this.email, this.password);
      }
    },

    forgot : function() {
      if (this.onForgot) this.onForgot();
    },

    reloadDetails() {
      if (this.details.first_name) this.first_name = this.details.first_name
      if (this.details.last_name) this.last_name = this.details.last_name
      if (this.details.email) this.email = this.details.email
    }
  },

  computed : {
    canSubmit : function() {
      return this.isValid(this.email, 'email') && this.isValid(this.password,'password') && this.isValid(this.first_name,'name') && this.isValid(this.last_name,'name')
    },
  },

  watch : {
    details() {
      this.reloadDetails()
    }
  }

}
</script>
