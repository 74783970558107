<template>
  <div class="search-categories">
    <button class="btn tag-btn" v-for="(cat, ii) in categories" :key="ii" @click="onClick(cat)">{{cat.name}}</button>
  </div>
</template>

<script>
export default {
  name: "SearchCategories",
  props: {
    categories: { type: Array }
  },

  methods : {
    onClick(cat) {
      this.$emit('change', cat)
    }
  }
}
</script>
