<template>
  <div>
    <ExpiredForm v-if="expired" />
    <div class="container favorites" v-if="!expired">
      <h2>{{t("My Favorites")}}</h2>

      <h3>{{t("Yoga Trainings")}}</h3>
      <div class="boxes light" v-if="!practiceFavoritesList.length">
        <Box>
          <h3>{{t("No favorites yet")}}</h3>
          <label><router-link to="/plan/yoga">{{t("Search Now")}}</router-link></label>
        </Box>
      </div>
      <div class="boxes scrolling large" v-if="practiceFavoritesList.length">
        <Scroller :items="practiceFavoritesList" :show-items="2" :link-prop="['list_name','practice_id']" link-prefix="/plan/yoga/" :infinite="true">
          <template v-slot:default="s">
            <Box :thumb="trainingThumb(s.item)">
              <h3>{{trainingTitle(s.item)}}</h3>
              <p><Flexy><img :src="TimeIcon" class="time-icon" /> <span>{{t("Training duration: {0} minutes", trainingDuration(s.item))}}</span></Flexy></p>
            </Box>
          </template>
        </Scroller>
      </div>

      <h3>{{t("Recipes")}}</h3>
      <div class="boxes light" v-if="!recipeFavoritesList.length">
        <Box>
          <h3>{{t("No favorites yet")}}</h3>
          <label><router-link to="/plan/recipes">{{t("Search Now")}}</router-link></label>
        </Box>
      </div>
      <div class="boxes scrolling large" v-if="recipeFavoritesList.length">
        <Scroller :items="recipeFavoritesList" :show-items="2" link-prop="recipe_id" link-prefix="/plan/recipes/view/" :infinite="true">
          <template v-slot:default="s">
            <Box :thumb="getRecipeThumb(s.item)">
              <h3>{{recipeTitle(s.item)}}</h3>
              <label><img :src="TimeIcon" /><p>{{t("Prep time: {0} minutes", recipePrepTime(s.item))}}</p></label>
            </Box>
          </template>
        </Scroller>
      </div>

    </div>
  </div>
</template>

<script>
import t from '@/lib/Locale';
import Fito from "@/lib/fito";
import Box from "@/components/ui/widgets/Box";
import TimeIcon from '@/assets/icons/time.svg';
import Scroller from "@/components/ui/widgets/Scroller";
import Flexy from "@/components/ui/widgets/Flexy";
import ExpiredForm from "@/components/forms/ExpiredForm";

export default {
  name: "FavoritesHome",
  components: {ExpiredForm, Flexy, Scroller, Box},
  data() {
    return {
      TimeIcon,
      expired: false,
      recipeFavorites : [],
      practiceFavorites : [],
      videos : [],
      mediaLists : [],
      recipes: [],
    }
  },
  async mounted() {
    const login = await Fito().Login.safeCheckLogin(true);
    if (!login) return this.$router.push('/plan/login');


    if (login.status === 'expired') this.expired = true;


    this.recipes = await Fito().RunAction('get-recipes')

    const meta = login.meta;
    if (meta['recipe-favorites']) this.recipeFavorites = meta['recipe-favorites']
    if (meta['practice-favorites']) this.practiceFavorites = meta['practice-favorites']

    this.videos = await Fito().Plan.getVideos()
    this.mediaLists = await Fito().Plan.getMediaLists()

  },
  computed : {
    practiceFavoritesList() {

      return this.practiceFavorites
      .map(it => {
        const list = this.mediaLists.find(l => l.id === it.list_id)
        return {
          ...it,
          list_name: list ? list.name : ''
        }
      })
    },
    recipeFavoritesList() {
      return this.recipeFavorites
    }
  },
  methods : {
    t,

    trainingTitle(d) {
      if (!this.mediaLists) return ''
      const list = this.mediaLists.find(it => it.id === d.list_id);
      if (!list) return ''
      const item = list.items.find(it => it.id === d.practice_id);
      return item.title
    },
    trainingDuration(d) {
      const list = this.mediaLists.find(it => it.id === d.list_id);
      if (!list) return ''
      const item = list.items.find(it => it.id === d.practice_id);
      if (!item) return '';
      const video = this.videos.find(it => it.id === item.src*1);
      if (!video) return '';
      return video.duration
    },

    trainingThumb(d) {
      const list = this.mediaLists.find(it => it.id === d.list_id);
      if (!list) return ''
      const item = list.items.find(it => it.id === d.practice_id);
      if (!item) return '';
      const video = this.videos.find(it => it.id === item.src*1);
      if (!video) return '';
      return video.thumbnail
    },

    getRecipeThumb(d) {
      const recipe = this.recipes.find(it => it.id === d);
      if (recipe && recipe.images && recipe.images.length > 0) return recipe.images[0].image
      return '';
    },

    recipeTitle(d) {
      const recipe = this.recipes.find(it => it.id === d);
      if (!recipe) return ''
      return recipe.name
    },
    recipePrepTime(d) {
      const recipe = this.recipes.find(it => it.id === d);
      if (!recipe) return ''
      return recipe.prep_time

    },
  }
}
</script>
