<template>
  <div class="container">
    <router-link to="/plan/recipes/categories" class="back-link">
      <Flexy v-if="category"><img :src="ArrowRightIcon" /> <p>{{category.name}}</p></Flexy>
    </router-link>
    <div class="boxes scrolling large">
      <Box :thumb="getThumb(recipe)" v-for="(recipe, ii) in categoryRecipes" :key="ii" :link="'/plan/recipes/view/'+recipe.id">
        <h3>{{recipe.name}}</h3>
        <Flexy class="p">
          <img :src="TimeIcon" />
          <p>{{t("Prep time: {0} minutes", recipe.prep_time)}}</p>
          <p v-for="(tag, ii) in allowedTags(recipe)" :key="ii">{{tag.name}}</p>
        </Flexy>
      </Box>
    </div>
  </div>
</template>

<script>

import Fito from '@/lib/fito';

import t from "@/lib/Locale";
import Box from "@/components/ui/widgets/Box";
import Flexy from "@/components/ui/widgets/Flexy";
import TimeIcon from '@/assets/icons/time.svg';
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';
import {applySavedFilters} from "@/lib/RecipeUtils";
import RecipePlaceholder from '@/assets/images/recipe-placeholder.png';

export default {

  name: "Recipes",
  components: {Flexy, Box},
  data : function() {
    return {
      TimeIcon, ArrowRightIcon,
      recipes : [],
      categories : [],
      tags: [],
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')


    this.recipes = await Fito().RunAction('get-recipes');
    this.categories = await Fito().RunAction('get-recipe-categories')
    this.tags = await Fito().RunAction('get-recipe-tags')

    this.recipes = applySavedFilters(this.recipes)

  },

  methods: {
    t,
    getThumb(recipe) {
      return recipe.images && recipe.images.length ? recipe.images[0].image : RecipePlaceholder
    },
    recipeTags(recipe) {
      let r = []
      for (let tag of recipe.tags) {
        const t = this.tags.find(t => t.id === tag.tag_id)
        if (t) r.push({...tag, name: t.name })
      }
      return r;
    },
    allowedTags(recipe) {
      if (!this.recipeTags(recipe).length) return []
      let allowed = ['פרווה','בטיחותי בהיריון','קל','כשר','צמחוני','טבעוני']
      return this.recipeTags(recipe).filter(it => allowed.indexOf(it.name) > -1)
    }
  },

  computed : {
    categoryRecipes() {
      const categoryId = this.$route.params.id*1;
      return this.recipes.filter(it => it.categories.filter(c => c.category_id === categoryId).length > 0)
    },
    category() {
      const categoryId = this.$route.params.id*1;
      return this.categories.find(it => it.id === categoryId)
    },
  },


}
</script>
